// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .css-1mgwkks-MuiTypography-root {
    color: #FDF6EA !important;
}

.css-56dwsw-MuiTypography-root {
  color: #FDF6EA !important;
}

.css-1jmtpmq-MuiTypography-root {
  color: #344767 !important;
}

.css-181qv1q-MuiTypography-root {
  color: #FDF6EA !important;
}
.css-1xn6mfa-MuiTypography-root {
  color: #FDF6EA !important;
}
.css-10aqyx0-MuiTypography-root {
  color: #FDF6EA !important;
}

.css-1b82nnz {
  padding-bottom: 8px !important;
}

.css-1abaosp-MuiTypography-root {
  color: #344767 !important;
}
.css-bbkpxe-MuiTypography-root {
  color: #344767 !important;
} */
.back-card {
  color: #344767 !important;
}
.ant-drawer-mask {
  background: rgb(0 0 0 / 4%) !important;
}

.boton_editar_como {
  margin-left: -83% !important;
}

@media only screen and (max-width: 576px) {
  .ubicacion_card {
    width: calc(100% + -23px) !important;
    padding-bottom: 75px !important;
    margin-left: -12px !important;
  }
  .boton_editar_como {
    margin-left: -83% !important;
  }
  /* .boton_proposito {
    display: grid !important;
    width: calc(100% + 100px) !important;
  } */
}

`, "",{"version":3,"sources":["webpack://./src/pages/Presentation/sections/como/style.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA+BG;AACH;EACE,yBAAyB;AAC3B;AACA;EACE,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,oCAAoC;IACpC,+BAA+B;IAC/B,6BAA6B;EAC/B;EACA;IACE,4BAA4B;EAC9B;EACA;;;KAGG;AACL","sourcesContent":["/* .css-1mgwkks-MuiTypography-root {\n    color: #FDF6EA !important;\n}\n\n.css-56dwsw-MuiTypography-root {\n  color: #FDF6EA !important;\n}\n\n.css-1jmtpmq-MuiTypography-root {\n  color: #344767 !important;\n}\n\n.css-181qv1q-MuiTypography-root {\n  color: #FDF6EA !important;\n}\n.css-1xn6mfa-MuiTypography-root {\n  color: #FDF6EA !important;\n}\n.css-10aqyx0-MuiTypography-root {\n  color: #FDF6EA !important;\n}\n\n.css-1b82nnz {\n  padding-bottom: 8px !important;\n}\n\n.css-1abaosp-MuiTypography-root {\n  color: #344767 !important;\n}\n.css-bbkpxe-MuiTypography-root {\n  color: #344767 !important;\n} */\n.back-card {\n  color: #344767 !important;\n}\n.ant-drawer-mask {\n  background: rgb(0 0 0 / 4%) !important;\n}\n\n.boton_editar_como {\n  margin-left: -83% !important;\n}\n\n@media only screen and (max-width: 576px) {\n  .ubicacion_card {\n    width: calc(100% + -23px) !important;\n    padding-bottom: 75px !important;\n    margin-left: -12px !important;\n  }\n  .boton_editar_como {\n    margin-left: -83% !important;\n  }\n  /* .boton_proposito {\n    display: grid !important;\n    width: calc(100% + 100px) !important;\n  } */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
