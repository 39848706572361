import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import React, { useState, useEffect } from "react";
import apiUrl from "domain";
import NavBar from "pages/Presentation/components/NavBar";
import axios from "axios";
import DefaultFooterVerde from "pages/Presentation/sections/footer/default";
// Routes
import footerRoutes from "footer.routes";
import bgImages from "assets/images/examples/blog2.jpg";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Drawer, Space, Upload } from "antd";
import { useTheme } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import Preguntas from "./preguntas";

function Contact() {
  const theme = useTheme();
  const [user_id, setUserId] = useState(null);
  const [roleUser, setRoleUser] = useState(null);
  const [userNames, setUserNames] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [message, setMessage] = useState(null);
  const [tituloContacto, setTituloContacto] = useState();
  const [tamanoContacto, setTamanoContacto] = useState();
  const [colorContacto, setColorContacto] = useState();
  const [textoContacto, setTextoContacto] = useState();
  const [tamanoTextoContacto, setTamanoTextoContacto] = useState();
  const [colorTextoContacto, setColorTextoContacto] = useState();
  // const [preguntas, setPreguntas] = useState([]);
  const [newLogo, setNewLogo] = useState();
  const [ids, setId] = useState();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  const [editOpen, setEditOpen] = useState(false);

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  /**
   * La función `getCookieValue` recupera el valor de una cookie por su nombre de las cookies del
   * documento.
   * @param name - El parámetro `name` en la función `getCookieValue` es una cadena que representa el
   * nombre de la cookie cuyo valor desea recuperar.
   * @returns La función `getCookieValue` devuelve el valor de la cookie con el nombre especificado. Si
   * se encuentra una cookie con el nombre de pila en las cookies del documento, la función devuelve el
   * valor de esa cookie. Si no se encuentra ninguna cookie coincidente, la función devuelve "nulo".
   */
  const getCookieValue = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  /**
   * La función fetchUserProfile envía una solicitud POST al punto final de la API de autenticación con
   * un ID de token y establece datos relacionados con el usuario en función de la respuesta.
   * @param token_id - El parámetro `token_id` es un identificador o token único que se utiliza para
   * autenticar e identificar a un usuario. Se pasa a la función `fetchUserProfile` para recuperar la
   * información del perfil de usuario asociada con ese token.
   */
  const fetchUserProfile = async (token_id) => {
    try {
      const csrftoken = getCookieValue("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/authentication/",
        { token_id },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      setUserId(response.data.user_id);
      setRoleUser(response.data.rol);
      setUserNames(response.data.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  /* El gancho `useEffect` en el fragmento de código se utiliza para realizar efectos secundarios en
  los componentes de la función. En este caso específico: */
  useEffect(() => {
    const token_id = getCookieValue("token");
    if (token_id) {
      fetchUserProfile(token_id);
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchBgImage = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/contactanos/?format=json");
      // const data = await response.json();
      setId(response.data[0].id);
      setBgImage(response.data[0].imagen);
      console.log("response.data[0]", response.data[0]);
      setTituloContacto(response.data[0].titulo);
      setTamanoContacto(response.data[0].tamano_titulo);
      setColorContacto(response.data[0].color_titulo);
      setTextoContacto(response.data[0].texto);
      setTamanoTextoContacto(response.data[0].tamano_texto);
      setColorTextoContacto(response.data[0].color_texto);
      console.log("tituloContacto", tituloContacto);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  // const fetchPreguntas = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/api/preguntas/?format=json");
  //     // const data = await response.json();
  //     setPreguntas(response.data);
  //   } catch (error) {
  //     console.error("Error fetching background image:", error);
  //   }
  // };

  useEffect(() => {
    fetchBgImage();
    // fetchPreguntas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(apiUrl + "/api/contacto/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setFormData({
          nombre: "",
          email: "",
          telefono: "",
          descripcion: "",
        });
        setMessage("Tu mensaje ha sido enviado correctamente. \n Pronto te contactaremos.");
      } else {
        setMessage("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessage("Failed to send message");
    }
  };

  const handleEditClose = () => {
    fetchBgImage();
    setEditOpen(false);
  };

  const handleSaveImagenFondo = async () => {
    try {
      const formData = new FormData();
      formData.append("imagen", newLogo);
      formData.append("titulo", tituloContacto);
      formData.append("texto", textoContacto);
      formData.append("tamano_titulo", tamanoContacto);
      formData.append("color_titulo", colorContacto);
      formData.append("tamano_texto", tamanoTextoContacto);
      formData.append("color_texto", colorTextoContacto);
      await axios.post(apiUrl + "/api/contactanos/update/" + ids, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchBgImage();
      setEditOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  return (
    <>
      <NavBar
        username={userNames}
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      &nbsp;&nbsp;<br></br>
      &nbsp;&nbsp;<br></br>
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container>
          &nbsp;&nbsp;<br></br>
          {user_id > 0 && (roleUser === "Administrador" || roleUser === "SuperUser") && (
            <>
              <IconButton
                onClick={handleEditOpen}
                aria-label="Editar"
                sx={{
                  backgroundColor: "#FF8C00",
                  "&:hover": {
                    backgroundColor: "#dfa967",
                    boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                  },
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          )}
          <Grid container item>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.1),
                        rgba(gradients.dark.state, 0.1)
                      )}, url(${apiUrl}${bgImage ? bgImage : bgImages})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <Grid item xs={12} lg={6}>
                  <MKBox component="form" p={2} method="post">
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MKTypography
                        variant="h4"
                        mb={1}
                        sx={{
                          fontSize: `${tamanoContacto ? tamanoContacto : 12}px`,
                          color: `${colorContacto ? colorContacto : "white"}`,
                        }}
                      >
                        {tituloContacto}
                      </MKTypography>
                      <MKTypography
                        variant="body1"
                        color="text"
                        mb={2}
                        sx={{
                          fontSize: `${tamanoTextoContacto ? tamanoTextoContacto : 12}px`,
                          color: `${colorTextoContacto ? colorTextoContacto : "white"}`,
                        }}
                      >
                        {textoContacto}
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0.5} pb={3} px={3}>
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="Nombre"
                            placeholder="Ingresa tu Nombre"
                            InputLabelProps={{ shrink: true }}
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            type="email"
                            variant="standard"
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Ingresa tu Email"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            variant="standard"
                            label="Telefono"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                            placeholder="Ingresa tu Telefono"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="Asunto"
                            placeholder="Ingresa tu Asunto"
                            InputLabelProps={{ shrink: true }}
                            name="asunto"
                            value={formData.asunto}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="¿Tienes alguna pregunta o comentario? ¡Estamos aquí para ayudarte!"
                            placeholder="Cuentanos tu necesidad..."
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={6}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="info" onClick={handleSubmit}>
                          Enviar Mensaje
                        </MKButton>
                      </Grid>
                      {message && (
                        <div
                          className={
                            message.includes("success") ? "success-message" : "error-message"
                          }
                        >
                          {message}
                        </div>
                      )}
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
          <Preguntas />
        </Container>
        <DefaultFooterVerde content={footerRoutes} />
      </MKBox>
      {/* <Collapse items={items} defaultActiveKey={["1"]} onChange={onChange} />;
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        {Array.isArray(preguntas) &&
          preguntas.map((logo) => (
            <Accordion.Item eventKey={logo.id} key={logo.id}>
              <Accordion.Header>{logo.pregunta}</Accordion.Header>
              <Accordion.Body>{logo.respuesta}</Accordion.Body>
            </Accordion.Item>
          ))}
      </MKBox> */}
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveImagenFondo}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <>
              <Upload
                name="imagen"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => setNewLogo(file)}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setNewLogo(info.file.originFileObj);
                  }
                }}
              >
                {newLogo ? (
                  <img src={URL.createObjectURL(newLogo)} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  <img src={`${apiUrl}${bgImage}`} alt="avatar" style={{ width: "100%" }} />
                )}
              </Upload>
              <Grid item>
                <SoftTypography variant="h6" fontWeight="regular" color="text">
                  &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Color&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="color"
                  value={colorContacto}
                  onChange={(e) => setColorContacto(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Tamaño&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="number"
                  value={tamanoContacto}
                  onChange={(e) => setTamanoContacto(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                <SoftTypography variant="h6" fontWeight="regular" color="text">
                  &nbsp;<br></br>Texto&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Color&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="color"
                  value={colorTextoContacto}
                  onChange={(e) => setColorTextoContacto(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Tamaño&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="number"
                  value={tamanoTextoContacto}
                  onChange={(e) => setTamanoTextoContacto(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
              &nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;
              <Grid item xs={12} sx={{ mb: 3 }}>
                <MKInput
                  type="text"
                  label="Título"
                  value={tituloContacto}
                  onChange={(e) => setTituloContacto(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Dirección"
                  value={textoContacto}
                  onChange={(e) => setTextoContacto(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
            </>
          </MKBox>
        </div>
      </Drawer>
    </>
  );
}

export default Contact;
