import Container from "@mui/material/Container";
// import "pages/Presentation/sections/proposito/style.css";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useTheme, useMediaQuery } from "@mui/material";
import MKButton from "components/MKButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import apiUrl from "domain";
import MuiLink from "@mui/material/Link";
import MKInput from "components/MKInput";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { Drawer, Space } from "antd";
import SoftTypography from "components/SoftTypography";
import { Upload } from "antd";
import PropTypes from "prop-types";

function Proposito({ user_id, roleUser }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [editMode, setEditMode] = useState(false);
  const [editedDataProposito, setEditedDataProposito] = useState({});
  const [propositoTitulo, setPropositoTitulo] = useState("");
  const [propositoTexto, setPropositoTexto] = useState("");
  const [propositoBtnTexto, setPropositoBtnTexto] = useState("");
  const [propositoBtnLink, setPropositoBtnLink] = useState("");
  const [propositoBtnnTexto, setPropositoBtnnTexto] = useState("");
  const [propositoBtnnLink, setPropositoBtnnLink] = useState("");
  const [propositoImagen, setPropositoImagen] = useState();
  const [tituloFontSize, setTituloFontSize] = useState();
  const [tituloColor, setTituloColor] = useState();
  const [textoFontSize, setTextoFontSize] = useState();
  const [textoColor, setTextoColor] = useState();
  const [fondoColor, setFondoColor] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [newLogo, setNewLogo] = useState();

  const fetchProposito = async () => {
    try {
      const response = await fetch(apiUrl + "/api/proposito/?format=json");
      const datas = await response.json();
      setEditedDataProposito(datas[0]);
      setTituloFontSize(datas[0].tamano_titulo);
      setTituloColor(datas[0].color_titulo);
      setTextoFontSize(datas[0].tamano_subtitulo);
      setTextoColor(datas[0].color_subtitulo);
      setFondoColor(datas[0].color_fondo);
      setPropositoTitulo(datas[0].titulo_proposito);
      setPropositoTexto(datas[0].subtitulo_proposito);
      setPropositoImagen(datas[0].imagen_proposito);
      setPropositoBtnTexto(datas[0].btn_texto);
      setPropositoBtnLink(datas[0].btn_link);
      setPropositoBtnnTexto(datas[0].btnn_texto);
      setPropositoBtnnLink(datas[0].btnn_link);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchProposito();
  }, []);

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSaveProposito = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_proposito", propositoTitulo);
      formData.append("subtitulo_proposito", propositoTexto);
      formData.append("btn_texto", propositoBtnTexto);
      formData.append("btn_link", propositoBtnLink);
      formData.append("btnn_texto", propositoBtnnTexto);
      formData.append("btnn_link", propositoBtnnLink);
      formData.append("tamano_titulo", tituloFontSize);
      formData.append("color_titulo", tituloColor);
      formData.append("tamano_subtitulo", textoFontSize);
      formData.append("color_subtitulo", textoColor);
      formData.append("color_fondo", fondoColor);
      formData.append("imagen_proposito", newLogo);
      await axios.post(apiUrl + "/api/proposito/update/" + editedDataProposito.id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setEditMode(false);
      fetchProposito();
      setEditOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      console.log(file);
      setPropositoImagen(file);
    };
    input.click();
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    fetchProposito();
    setEditOpen(false);
  };
  return (
    <MKBox
      id="proposito"
      component="section"
      py={{ xs: 3, md: 12 }}
      sx={{
        position: "relative",
        backgroundColor: fondoColor,
        boxSizing: "unser",
      }}
    >
      <Container>
        {user_id > 0 &&
          (roleUser === "Administrador" || roleUser === "SuperUser") &&
          (editMode ? (
            <>
              <IconButton onClick={handleCancelEdit} aria-label="Cancelar">
                <CancelIcon />
              </IconButton>
              <IconButton onClick={handleSaveProposito} aria-label="Guardar">
                <SaveIcon />
              </IconButton>
            </>
          ) : (
            <IconButton
              className="boton_editar"
              onClick={handleEditOpen}
              aria-label="Editar"
              sx={{
                backgroundColor: "#FF8C00",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
              }}
            >
              <EditIcon />
            </IconButton>
          ))}
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{
            display: "flex",
            flexDirection: "row",
            masxWidth: "80%",
          }}
          // xs={12}
          // lg={8}
        >
          <Grid
            className="ubicacion_texto"
            // alignItems={"center"}
            item
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // masxWidth: "80%",
              // height: "100%",
              ml: isDesktop ? { xs: -2, lg: "auto" } : 0,
              mt: { xs: 5, lg: 0 },
            }}
          >
            <MKTypography
              variant="h1"
              my={1}
              sx={{
                fontSize: `${isDesktop ? tituloFontSize - 15 : tituloFontSize - 15}px`,
                color: tituloColor,
                fontFamily: "Satoshi Regular",
                // fontWeight: "normal",
                textAlign: isDesktop ? "" : "center",
              }}
            >
              {propositoTitulo}
            </MKTypography>
            <MKTypography
              variant="body"
              color="text"
              mb={2}
              sx={{
                fontSize: `${isDesktop ? textoFontSize - 8 : textoFontSize - 8}px`,
                color: textoColor,
                textAlign: isDesktop ? "" : "center",
                fontFamily: "Satoshi Regular",
                // fontWeight: "normal",
              }}
            >
              {propositoTexto}&nbsp;&nbsp;
            </MKTypography>
            &nbsp;<br></br>&nbsp;
            {/* <Grid container spacing={7} justifyContent={"center"} className="boton_proposito"> */}
            <MKButton
              className="boton_proposito"
              component={MuiLink}
              href={`#${propositoBtnLink}`}
              color="primary"
              sx={{
                backgroundColor: "#FF8C00",
                boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
                maxWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Satoshi Regular",
                textAlign: "center",
              }}
            >
              {propositoBtnTexto}
            </MKButton>
            <Icon sx={{ fontWeight: "bold" }}>&nbsp;</Icon>
            <MKButton
              component={MuiLink}
              href={`#${propositoBtnnLink}`}
              color="primary"
              sx={{
                backgroundColor: "#FF8C00",
                boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
                maxWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Satoshi Regular",
                textAlign: "center",
              }}
            >
              {propositoBtnnTexto}
            </MKButton>
            {/* </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              ml: isDesktop ? { xs: -2, lg: "auto" } : 0,
              mt: { xs: 6, lg: 0 },
            }}
          >
            <MKBox
              className="ubicacion_imagen"
              component="img"
              src={
                editMode
                  ? propositoImagen instanceof File
                    ? URL.createObjectURL(propositoImagen)
                    : `${apiUrl}${propositoImagen}`
                  : `${apiUrl}${editedDataProposito.imagen_proposito}`
              }
              onDoubleClick={() => {
                if (editMode) {
                  handleDoubleClick();
                }
              }}
              alt="Imagen de propósito"
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveProposito}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <Upload
              name="imagen"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => setNewLogo(file)}
              onChange={(info) => {
                if (info.file.status === "done") {
                  setNewLogo(info.file.originFileObj);
                }
              }}
            >
              {newLogo ? (
                <img src={URL.createObjectURL(newLogo)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <img src={`${apiUrl}${propositoImagen}`} alt="avatar" style={{ width: "100%" }} />
              )}
            </Upload>
            <>
              <SoftTypography variant="h6" fontWeight="regular" color="text">
                &nbsp;<br></br>Fondo&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Color&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="color"
                value={fondoColor}
                onChange={(e) => setFondoColor(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              <Grid container alignItems="center" spacing={-2}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <SoftTypography variant="h6" fontWeight="regular" color="text">
                    &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="color"
                    value={tituloColor}
                    onChange={(e) => setTituloColor(e.target.value)}
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Tamaño&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="number"
                    value={tituloFontSize}
                    onChange={(e) => setTituloFontSize(e.target.value)}
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Texto&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="text"
                    value={propositoTitulo}
                    onChange={(e) => setPropositoTitulo(e.target.value)}
                    sx={{
                      width: "100%",
                      resize: "none",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                </Grid>
              </Grid>
            </>
            <Grid item>
              <SoftTypography variant="h6" fontWeight="regular" color="text">
                &nbsp;<br></br>Texto&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Color&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="color"
                value={textoColor}
                onChange={(e) => setTextoColor(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Tamaño&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="number"
                value={textoFontSize}
                onChange={(e) => setTextoFontSize(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Contenido&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="text"
                value={propositoTexto}
                onChange={(e) => setPropositoTexto(e.target.value)}
                multiline
                minRows={3}
                maxRows={10}
                sx={{
                  width: "100%",
                  resize: "none",
                  backgroundColor: "#ffffff",
                }}
              />
            </Grid>
            <SoftTypography variant="h6" fontWeight="regular" color="text">
              &nbsp;<br></br>Botones&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Texto&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            &nbsp;<br></br>
            <MKInput
              type="text"
              value={propositoBtnTexto}
              onChange={(e) => setPropositoBtnTexto(e.target.value)}
              sx={{
                backgroundColor: "#ffffff",
              }}
            />
            &nbsp;<br></br>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              URL&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            &nbsp;<br></br>
            <MKInput
              type="text"
              value={propositoBtnLink}
              onChange={(e) => setPropositoBtnLink(e.target.value)}
              sx={{
                backgroundColor: "#ffffff",
              }}
            />
          </MKBox>
          <MKBox py={{ xs: 1, md: 2 }}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Texto&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            &nbsp;<br></br>
            <MKInput
              type="text"
              value={propositoBtnnTexto}
              onChange={(e) => setPropositoBtnnTexto(e.target.value)}
              sx={{
                backgroundColor: "#ffffff",
              }}
            />
            &nbsp;<br></br>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              URL&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            &nbsp;<br></br>
            <MKInput
              type="text"
              value={propositoBtnnLink}
              onChange={(e) => setPropositoBtnnLink(e.target.value)}
              sx={{
                backgroundColor: "#ffffff",
              }}
            />
          </MKBox>
        </div>
      </Drawer>
    </MKBox>
  );
}

Proposito.propTypes = {
  roleUser: PropTypes.string.isRequired,
  user_id: PropTypes.number,
};

export default Proposito;
