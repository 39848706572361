import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { useTheme, Box } from "@mui/material";
import PropTypes from "prop-types";

function ColorPickerComponent({ fondoColor, setFondoColor }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const theme = useTheme();

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setFondoColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
  };

  return (
    <div>
      <Box
        onClick={handleClick}
        sx={{
          width: "200px",
          height: "30px",
          marginRight: theme.spacing(8),
          backgroundColor: fondoColor,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "4px",
          cursor: "pointer",
        }}
      />
      {displayColorPicker && (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <div
            style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" }}
            onClick={handleClose}
          />
          <ChromePicker color={fondoColor} onChange={handleChange} />
        </div>
      )}
    </div>
  );
}

ColorPickerComponent.propTypes = {
  fondoColor: PropTypes.string,
  setFondoColor: PropTypes.func,
};

export default ColorPickerComponent;
