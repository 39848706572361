import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "domain";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import NavBar from "pages/Presentation/components/NavBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import bgImages from "assets/images/bg-sign-in-basic.jpeg";

function RestaurarPassword() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "", // Nuevo campo para confirmar contraseña
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [bgImage, setBgImage] = useState(null);
  const [showResetForm, setShowResetForm] = useState(false);
  const [tokens, setTokens] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchBgImage = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/imagenesfondo/?format=json");
      setBgImage(response.data[0].imagen_contactanos);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchBgImage();
    getCookie("csrftoken");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const csrftoken = getCookie("csrftoken");

    try {
      // if (formData.password !== formData.confirmPassword) {
      //   setErrorMessage("Las contraseñas no coinciden.");
      //   return;
      // }

      // const response = await axios.post(
      //   apiUrl + "/api/password-reset/response/",
      //   {
      //     email: formData.email,
      //     newPassword: formData.password,
      //   },
      //   {
      //     headers: {
      //       "X-CSRFToken": csrftoken,
      //     },
      //     withCredentials: true,
      //   }
      // );
      const response = await axios.post(
        `${apiUrl}/api/password-change/`,
        {
          email: formData.email,
        },
        {
          headers: {
            "X-CSRFToken": csrftoken,
          },
          withCredentials: false,
        }
      );

      console.log("response", response);
      setTokens(response.data.token);
      console.log("TOKENss", tokens);
      setSuccessMessage(response.data.message);
      console.log("TOKEN", tokens);
      setShowResetForm(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Error al reestablecer la contraseña. Por favor, inténtalo de nuevo."); // Establecer mensaje de error
    }
  };

  const handleSavePassword = async (e) => {
    e.preventDefault(); // Prevenir el envío predeterminado del formulario

    try {
      const response = await axios.post(`${apiUrl}/api/password-change/pass`, {
        newPassword: formData.password,
        confirmNewPassword: formData.confirmPassword,
        token: formData.token,
        email: formData.email,
      });
      console.log("response", response.data.message);
      setErrorMessage("");
      if (response.status === 200) {
        setSuccessMessage("Contraseña restablecida exitosamente.");
      } else {
        setErrorMessage("Error al reestablecer la contraseña. Por favor, inténtalo de nuevo.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("");
      // setErrorMessage("Error al reestablecer la contraseña. Por favor, inténtalo de nuevo.");
      setErrorMessage("");
      setErrorMessage(error.response.data.message);
    } finally {
      setFormData({
        email: "",
        password: "",
        confirmPassword: "",
        token: "",
      });
    }
  };

  return (
    <>
      <NavBar />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: () =>
            `url(${bgImage ? `${apiUrl}/${bgImage}` : `${apiUrl}/${bgImages}`})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Reestablecer Contraseña
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {!showResetForm ? (
                  <form onSubmit={handleSubmit}>
                    <MKBox mb={2}>
                      <MKInput
                        type="email"
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton type="submit" variant="gradient" color="info" fullWidth>
                        Reestablecer Contraseña
                      </MKButton>
                    </MKBox>
                    {errorMessage && (
                      <MKTypography color="error" variant="subtitle2">
                        {errorMessage}
                      </MKTypography>
                    )}
                    {successMessage && (
                      <MKTypography color="success" variant="subtitle2">
                        {successMessage}
                      </MKTypography>
                    )}
                  </form>
                ) : (
                  <form onSubmit={handleSavePassword}>
                    <MKBox mb={2}>
                      <MKInput
                        type="email"
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        name="token"
                        label="Token"
                        value={formData.token}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="password"
                        name="password"
                        label="Nueva Contraseña"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="password"
                        name="confirmPassword"
                        label="Confirmar Contraseña"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton type="submit" variant="gradient" color="info" fullWidth>
                        Reestablecer Contraseña Final
                      </MKButton>
                    </MKBox>
                    {errorMessage && (
                      <MKTypography color="error" variant="subtitle2">
                        {errorMessage}
                      </MKTypography>
                    )}
                    {successMessage && (
                      <MKTypography color="success" variant="subtitle2">
                        {successMessage}
                      </MKTypography>
                    )}
                  </form>
                )}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default RestaurarPassword;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import apiUrl from "domain";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
// import NavBar from "pages/Presentation/components/NavBar";
// import SimpleFooter from "examples/Footers/SimpleFooter";
// import bgImages from "assets/images/bg-sign-in-basic.jpeg";

// function RestaurarPassword() {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     confirmPassword: "", // Nuevo campo para confirmar contraseña
//   });
//   const [errorMessage, setErrorMessage] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");
//   const [bgImage, setBgImage] = useState(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   function getCookie(cname) {
//     let name = cname + "=";
//     let decodedCookie = decodeURIComponent(document.cookie);
//     let ca = decodedCookie.split(";");
//     for (let i = 0; i < ca.length; i++) {
//       let c = ca[i];
//       while (c.charAt(0) === " ") {
//         c = c.substring(1);
//       }
//       if (c.indexOf(name) === 0) {
//         return c.substring(name.length, c.length);
//       }
//     }
//     return "";
//   }

//   const fetchBgImage = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/api/imagenesfondo/?format=json");
//       setBgImage(response.data[0].imagen_contactanos);
//     } catch (error) {
//       console.error("Error fetching background image:", error);
//     }
//   };

//   useEffect(() => {
//     fetchBgImage();
//     getCookie("csrftoken");
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const csrftoken = getCookie("csrftoken");

//     try {
//       if (formData.password !== formData.confirmPassword) {
//         setErrorMessage("Las contraseñas no coinciden.");
//         return;
//       }

//       const response = await axios.post(
//         apiUrl + "/api/password-reset/response/",
//         {
//           email: formData.email,
//           newPassword: formData.password,
//         },
//         {
//           headers: {
//             "X-CSRFToken": csrftoken,
//           },
//           withCredentials: true,
//         }
//       );

//       console.log("response", response);

//       if (response.status === 200) {
//         // Redireccionar a la página de inicio de sesión
//         window.location.href = "/login";
//         setSuccessMessage(response.data.message);
//       } else {
//         setErrorMessage(""); // Limpiar mensaje de error
//         setErrorMessage("Error al reestablecer la contraseña. Por favor, inténtalo de nuevo.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setErrorMessage("Error al reestablecer la contraseña. Por favor, inténtalo de nuevo."); // Establecer mensaje de error
//     }
//   };

//   return (
//     <>
//       <NavBar />
//       <MKBox
//         position="absolute"
//         top={0}
//         left={0}
//         zIndex={1}
//         width="100%"
//         minHeight="100vh"
//         sx={{
//           backgroundImage: () =>
//             `url(${bgImage ? `${apiUrl}/${bgImage}` : `${apiUrl}/${bgImages}`})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//         }}
//       />
//       <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
//         <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
//           <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
//             <Card>
//               <MKBox
//                 variant="gradient"
//                 bgColor="info"
//                 borderRadius="lg"
//                 coloredShadow="info"
//                 mx={2}
//                 mt={-3}
//                 p={2}
//                 mb={1}
//                 textAlign="center"
//               >
//                 <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
//                   Reestablecer Contraseña
//                 </MKTypography>
//               </MKBox>
//               <MKBox pt={4} pb={3} px={3}>
//                 <form onSubmit={handleSubmit}>
//                   <MKBox mb={2}>
//                     <MKInput
//                       type="email"
//                       name="email"
//                       label="Email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       fullWidth
//                     />
//                   </MKBox>
//                   <MKBox mb={2}>
//                     <MKInput
//                       type="password"
//                       name="password"
//                       label="Nueva Contraseña"
//                       value={formData.password}
//                       onChange={handleChange}
//                       fullWidth
//                     />
//                   </MKBox>
//                   <MKBox mb={2}>
//                     <MKInput
//                       type="password"
//                       name="confirmPassword"
//                       label="Confirmar Contraseña"
//                       value={formData.confirmPassword}
//                       onChange={handleChange}
//                       fullWidth
//                     />
//                   </MKBox>
//                   <MKBox mt={4} mb={1}>
//                     <MKButton type="submit" variant="gradient" color="info" fullWidth>
//                       Reestablecer Contraseña
//                     </MKButton>
//                   </MKBox>
//                   {errorMessage && (
//                     <MKTypography color="error" variant="subtitle2">
//                       {errorMessage}
//                     </MKTypography>
//                   )}
//                   {successMessage && (
//                     <MKTypography color="success" variant="subtitle2">
//                       {successMessage}
//                     </MKTypography>
//                   )}
//                 </form>
//               </MKBox>
//             </Card>
//           </Grid>
//         </Grid>
//       </MKBox>
//       <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
//         <SimpleFooter light />
//       </MKBox>
//     </>
//   );
// }

// export default RestaurarPassword;
