// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tamano_imagen_login {
  width: 80% !important;
}

@media screen and (max-width: 960px) {
    .tamano_imagen_login {
      width: 80% !important;
    }

  }
  @media screen and (max-width: 600px) {
    .tamano_imagen_login {
      width: 80% !important;
    }

  }
  @media screen and (max-width: 499px) {
    .tamano_imagen_login {
      width: 70% !important;
    }

  }
`, "",{"version":3,"sources":["webpack://./src/pages/LandingPages/SignIn/login.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;IACI;MACE,qBAAqB;IACvB;;EAEF;EACA;IACE;MACE,qBAAqB;IACvB;;EAEF;EACA;IACE;MACE,qBAAqB;IACvB;;EAEF","sourcesContent":[".tamano_imagen_login {\n  width: 80% !important;\n}\n\n@media screen and (max-width: 960px) {\n    .tamano_imagen_login {\n      width: 80% !important;\n    }\n\n  }\n  @media screen and (max-width: 600px) {\n    .tamano_imagen_login {\n      width: 80% !important;\n    }\n\n  }\n  @media screen and (max-width: 499px) {\n    .tamano_imagen_login {\n      width: 70% !important;\n    }\n\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
