import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "pages/Presentation/sections/filosofia/style.css";
import SoftAvatar from "components/SoftAvatar";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import { LinkedIn } from "@mui/icons-material";

function HorizontalCardAutor({ image, name, position, instagram, twitter, linkedin, facebook }) {
  console.log("position", position);
  return (
    <Card sx={{ mt: 0, backgroundColor: "#ffffff00" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <MKBox
            width="100%"
            p={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              overflow: "hidden",
            }}
          >
            {/* <MKBox
              className="ubicacion_imagen_filosofia"
              component="img"
              src={image}
              alt={image}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            /> */}
            <SoftAvatar
              src={image}
              alt={image}
              size="xl"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <>
            <MKBox p={2} lineHeight={1}>
              Autor:
              <MKTypography variant="h5">{name ? name : ""}</MKTypography>
              {/* <MKTypography
                variant="h6"
                // color={position.color ? position.color : ""}
                mb={1}
                sx={{
                  //   fontSize: `${position.tamano ? position.tamano : 12}px`,
                  //   color: `${position.color ? position.color : "white"}`,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {title ? title : ""}
              </MKTypography> */}
              {instagram && (
                <MKTypography
                  component="a"
                  href={instagram}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <InstagramIcon />
                </MKTypography>
              )}
              {facebook && (
                <>
                  &nbsp;&nbsp;
                  <MKTypography
                    component="a"
                    href={facebook}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    color="dark"
                    opacity={0.8}
                  >
                    <FacebookIcon />
                  </MKTypography>
                </>
              )}
              {twitter && (
                <>
                  &nbsp;&nbsp;
                  <MKTypography
                    component="a"
                    href={twitter}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    color="dark"
                    opacity={0.8}
                  >
                    <XIcon />
                  </MKTypography>
                </>
              )}
              {linkedin && (
                <>
                  &nbsp;&nbsp;
                  <MKTypography
                    component="a"
                    href={linkedin}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    color="dark"
                    opacity={0.8}
                  >
                    <LinkedIn />
                  </MKTypography>
                </>
              )}
              {/* <MKTypography
                variant="body2"
                color="text"
                sx={{
                  //   fontSize: `${position.tamanotexto ? position.tamanotexto : 25}px`,
                  //   color: `${position.colortexto ? position.colortexto : "white"}`,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {description ? description : ""}
              </MKTypography> */}
            </MKBox>
          </>
        </Grid>
      </Grid>
    </Card>
  );
}

HorizontalCardAutor.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.shape({
    // color: PropTypes.oneOf([
    //   "primary",
    //   "secondary",
    //   "info",
    //   "success",
    //   "warning",
    //   "error",
    //   "dark",
    //   "light",
    // ]),
    color: PropTypes.string,
    colortexto: PropTypes.string,
    tamano: PropTypes.number,
    tamanotexto: PropTypes.number,
    label: PropTypes.string,
  }),
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string,
  facebook: PropTypes.string,
};

export default HorizontalCardAutor;
