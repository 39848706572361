import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MKTypography from "components/MKTypography";
import logoCT from "assets/images/favicon.ico";
import InstagramIcon from "@mui/icons-material/Instagram";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "VeᏡdeSer",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=61555135696850",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/somosverdeser/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@Verdeser-fv2mg",
    },
  ],
  menus: [
    {
      name: "Dirección",
      items: [
        { name: "Kilometro 52 Vía Pto Lopez", href: "/" },
        { name: "Pachaquiaro - Meta ", href: "/" },
        { name: "Colombia", href: "/" },
      ],
    },
    {
      name: "Contact",
      items: [
        { name: "Tele: +57 (317) 5100-698", href: "/" },
        { name: "contacto@somosverdeser.com", href: "" },
      ],
    },
    {
      name: "Legal",
      items: [{ name: "Tratamiento de datos", href: "/Tratamiento" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://somosverdeser.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        VeᏡdeSer
      </MKTypography>
      .
    </MKTypography>
  ),
};
