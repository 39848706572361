/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import "pages/Presentation/sections/como/style.css";

function RotatingCardLanding({ fondo, image, title, description, action }) {
  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      // borderRadius="lg"
      coloredShadow={"#626949"}
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={5}
      sx={{
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
        transform: "rotateY(180deg)",
        ...(image
          ? {
              backgroundImage: `url(${image})`, // Asegúrate de que la variable `imagen` esté correctamente definida y contenga la URL de la imagen.
            }
          : {
              backgroundColor: fondo, // Asegúrate de que la variable `fondo` esté correctamente definida y contenga el color deseado.
            }),
      }}
    >
      <MKBox pt={12} pb={2} px={2} textAlign="center" lineHeight={1}>
        <>
          <MKTypography variant="h3" color="red" gutterBottom>
            {title ? title : ""}
          </MKTypography>
          {/* <MKTypography variant="body" color="white" opacity={0.8}>
            {description}
          </MKTypography> */}
          {description.split("\n").map((line, index) => (
            <MKTypography
              key={index}
              alignItems="center"
              variant="body"
              color="white"
              opacity={0.8}
              className="back-card"
            >
              {line.split("*").map((part, partIndex) => {
                if (partIndex % 2 === 0) {
                  return part; // Parte normal del texto
                } else {
                  return (
                    <strong key={partIndex}>
                      * {part}
                      <br></br>
                      <br></br>
                    </strong>
                  ); // Parte en negrita
                }
              })}
            </MKTypography>
          ))}
          {action && (
            <MKBox width="50%" mt={4} mb={2} mx="auto">
              {action.type === "external" ? (
                <MKButton
                  component={MuiLink}
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  color="info"
                  size="small"
                  fullWidth
                  sx={{
                    backgroundColor: "#FF8C00",
                    "&:hover": {
                      backgroundColor: "#dfa967", // Fondo rojo al pasar el mouse
                      boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                    },
                  }}
                >
                  {action.label}
                </MKButton>
              ) : (
                <MKButton component={Link} to={action.route} color="info" size="small" fullWidth>
                  {action.label}
                </MKButton>
              )}
            </MKBox>
          )}
        </>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the RotatingCardLanding
// RotatingCardLanding.defaultProps = {
//   color: "info",
// };

// Typechecking props for the RotatingCardLanding
RotatingCardLanding.propTypes = {
  // color: PropTypes.oneOf([
  //   "primary",
  //   "secondary",
  //   "info",
  //   "success",
  //   "warning",
  //   "error",
  //   "dark",
  //   "light",
  // ]),
  // color: PropTypes.string,
  image: PropTypes.string,
  fondo: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
};

export default RotatingCardLanding;
