import React, { useState, useEffect } from "react";
import axios from "axios";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import apiUrl from "domain";
import PropTypes from "prop-types";
import MKInput from "components/MKInput";
import Rating from "@mui/material/Rating";
import { Grid } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery, useTheme } from "@mui/material";
import ReviewCardComent from "./cardComentary";
import Divider from "@mui/material/Divider";
import "pages/Presentation/sections/blogs/styles.css";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = new Date(dateString).toLocaleDateString("en-US", options);
  return formattedDate;
};

const Comentarios = ({ postId }) => {
  const [textComment, setTextComment] = useState("");
  const [user_id, setUserId] = useState(null);
  const [email, setemail] = useState("");
  const [nombre, setnombre] = useState("");
  const [value, setValue] = useState(3);
  const [counterData, setCounterData] = useState([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchUserProfile = async (token_id) => {
    try {
      const csrftoken = getCookie("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/authentication/",
        { token_id },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      setUserId(response.data.user_id);
      setemail(response.data.username);
      setnombre(response.data.nombre);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchBgComentarios = async () => {
    try {
      const response = await fetch(apiUrl + "/api/post/comentarios/" + postId + "/?format=json");
      const data = await response.json();
      setCounterData(data);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    const token_id = getCookie("token");
    if (token_id) {
      fetchUserProfile(token_id);
    }
    fetchBgComentarios();
  }, []);

  const handleTextChange = (event) => {
    setTextComment(event.target.value);
  };

  const handleNombreChange = (event) => {
    setnombre(event.target.value);
  };

  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (!textComment) {
        alert("Por favor, ingresa un comentario.");
        return;
      }
      const formData = new FormData();
      formData.append("texto", textComment);
      formData.append("calificacion", value);
      formData.append("post_id", postId);
      formData.append("nombre", nombre);
      formData.append("email", email);
      await axios.post(apiUrl + "/api/comentario/create/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setTextComment("");
    } catch (error) {
      console.error("Error al enviar testimonio:", error);
    }
    fetchBgComentarios();
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", margin: "0px" }}>
        <Rating
          name="hover-feedback"
          value={value}
          precision={1}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        />
        {user_id < 1 && (
          <Grid container justifyContent="center">
            &nbsp;&nbsp;<br></br>&nbsp;&nbsp;
            <MKBox display="flex" justifyContent="center" my={0} sx={{ borderColor: "#000000" }}>
              &nbsp;&nbsp;<br></br>&nbsp;&nbsp;
              <MKInput
                value={nombre}
                onChange={handleNombreChange}
                placeholder="Escribe tu nombre..."
                style={{
                  width: "215px",
                  height: "73px",
                  borderColor: "#000000",
                }}
              />
            </MKBox>
            &nbsp;&nbsp;<br></br>&nbsp;&nbsp;
            <MKBox display="flex" justifyContent="center" my={0} sx={{ borderColor: "#000000" }}>
              <MKInput
                value={email}
                onChange={handleEmailChange}
                placeholder="Escribe tu email..."
                style={{
                  width: "215px",
                  height: "73px",
                  fontFamily: "Satoshi Regular",
                  borderColor: "#000000",
                }}
              />
            </MKBox>
          </Grid>
        )}
      </div>
      <MKInput
        value={textComment}
        onChange={handleTextChange}
        placeholder="Escribe tu comentario..."
        multiline
        minRows={3}
        maxRows={10}
        sx={{
          width: "100%",
          resize: "none",
          fontFamily: "Satoshi Regular",
          borderColor: "#000000",
        }}
      />
      <MKButton
        color="verdeser"
        onClick={() => handleSubmit()}
        sx={{
          ml: { xs: 0, md: 2 },
          mt: { xs: 2, md: 0 },
          backgroundColor: "#FF8C00",
          "&:hover": {
            backgroundColor: "#dfa967",
            boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
          },
          color: "#ffffff",
        }}
      >
        Enviar
      </MKButton>
      <br></br>&nbsp;&nbsp;
      <br></br>&nbsp;&nbsp;
      <br></br>&nbsp;&nbsp;
      {counterData.length > 0 && (
        <MKBox>
          <Divider sx={{ my: 0 }} />
          <Grid item xs={12} className="splide_tamano_comentario">
            <Divider sx={{ my: 0 }} />
            <Splide
              options={{ type: "loop", perPage: isDesktop ? 4 : 1, autoplay: true }} // Ajusta perPage dinámicamente
              aria-label="React Splide Example"
            >
              <Divider sx={{ my: 0 }} />
              <br></br>&nbsp;&nbsp;
              {counterData.map((logo) => (
                <>
                  <br></br>&nbsp;&nbsp;
                  <br></br>&nbsp;&nbsp;
                  <br></br>&nbsp;&nbsp;
                  <br></br>&nbsp;&nbsp;
                  <SplideSlide key={logo.id}>
                    <br></br>&nbsp;&nbsp;
                    <Grid item xs={12}>
                      <br></br>&nbsp;&nbsp;
                      <ReviewCardComent
                        name={logo.nombre}
                        date={formatDate(logo.created_at)}
                        review={logo.texto}
                        rating={logo.calificacion}
                      />
                    </Grid>
                  </SplideSlide>
                </>
              ))}
            </Splide>
          </Grid>
        </MKBox>
      )}
      <style>
        {`
        .is-active {
          width: calc(35%) !important;
        }
        @media only screen and (max-width: 576px) {
          .is-active {
            width: calc(400%) !important;
          }
        }
      `}
      </style>
    </>
  );
};

Comentarios.propTypes = {
  postId: PropTypes.string,
};

export default Comentarios;
