import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  letra,
  tamano,
  ...rest
}) {
  return (
    <>
      <MKBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color={letra}
        opacity={light ? 1 : 1.8}
        sx={{ cursor: "pointer", userSelect: "none" }}
        {...(route && { component: Link, to: route })}
        {...(href && {
          component: "a",
          href,
          target: "_blank",
          rel: "noreferrer",
        })}
      >
        <>
          <MKTypography
            variant="body2"
            lineHeight={1}
            color="inherit"
            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
          >
            <>{icon}</>
          </MKTypography>
          <MKTypography
            variant="h6"
            fontWeight="bold"
            textTransform="capitalize"
            color={letra ? letra : "white"}
            sx={{ fontWeight: "bold", ml: 1, mr: 0.25, fontSize: `${tamano}px` }}
          >
            <>{name}</>
          </MKTypography>
          <MKTypography variant="body" color={light ? "white" : "dark"} ml="auto">
            <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
              {collapse && "keyboard_arrow_down"}
            </Icon>
          </MKTypography>
        </>
      </MKBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: "",
  route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
  letra: PropTypes.string,
  tamano: PropTypes.number,
};

export default DefaultNavbarDropdown;
