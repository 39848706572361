import React, { useState, useEffect } from "react";
// import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import apiUrl from "domain";
import apiUrl_admin from "admin_domain";
import GoogleIcon from "@mui/icons-material/Google";
import MKTypography from "components/MKTypography";

function Logins() {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [message, setMessage] = useState("");
  const [access_token, setaccess_token] = useState();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      "; path=/; domain=" +
      process.env.REACT_APP_COOKIES_URL;
  };

  const handleLogin = async () => {
    try {
      console.log("profile", profile);
      const csrftoken = getCookie("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/google-login/",
        { user, access_token },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      console.log("response.data", response.data);
      setMessage("Login");
      console.log(message);
      const { access, token, user_id, messaje } = response.data;
      localStorage.setItem("token", access);
      sessionStorage.setItem("token", access);
      localStorage.setItem("user_id", user_id);
      document.cookie = `token=${token}; domain=.somosverdeser.com; path=/`;
      setCookie("token", token, 1);
      console.log("messaje", messaje);

      setTimeout(() => {
        window.location.href = `${apiUrl_admin}`;
      }, 2000);
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setMessage("Usuario o contraseña incorrectos.");
    }
  };

  const handleLoginGoogle = async () => {
    setaccess_token(user.access_token);
    axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        setProfile(res.data);
        console.log("res.data", res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (user) {
      handleLoginGoogle();
      handleLogin();
      console.log("profilesss", user);
    }
  }, [user]);

  // log out function to log the user out of google and set the profile array to null
  // const logOut = () => {
  //   googleLogout();
  //   setProfile(null);
  // };

  return (
    <>
      <MKTypography
        // component={MuiLink}
        // href={`${apiUrl}/accounts/google/login/`}
        variant="body1"
        color="white"
        target="_blank"
        onClick={() => login()}
      >
        <GoogleIcon color="inherit" />
      </MKTypography>
      {/* <button onClick={() => login()}>Sign in with Google 🚀 </button> */}
    </>
  );
}
export default Logins;
