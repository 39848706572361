// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("pages/Presentation/sections/Satoshi-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Satoshi Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Satoshi Regular', sans-serif;
}

.text {
  /* font-weight: 600; */
  /* font-size: 8em; */
  will-change: opacity;
}

.container {
  display: flex;
  align-items: center;
  /* height: 100%; */
  justify-content: center;
}

.imagen_banner {
  text-align: center !important;
  align-items: start !important;
  /* min-height: 0px !important; */
}
.boton_editar_imagen {
  margin-left: -83% !important;
}
@media only screen and (max-width: 960px) {
  .boton_editar_imagen {
    margin-left: -83% !important;
  }
  .titulo_banner {
    font-size: 36px !important;
  }

  .texto_banner {
    font-size: 26px !important;
  }
  .imagen_banner {
    text-align: center !important;
    align-items: start !important;
    min-height: 600px !important;
  }
}
@media only screen and (max-width: 600px) {
  .imagen_banner {
    min-height: 380px !important;
    min-width: 80px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Presentation/sections/imagen/styles.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,+DAA8E;EAC9E,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE,4BAA4B;AAC9B;AACA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;EACA;IACE,6BAA6B;IAC7B,6BAA6B;IAC7B,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,4BAA4B;IAC5B,0BAA0B;EAC5B;AACF","sourcesContent":["@font-face {\r\n  font-family: 'Satoshi Regular';\r\n  src: url(\"pages/Presentation/sections/Satoshi-Regular.ttf\") format('truetype');\r\n  font-weight: normal;\r\n  font-style: normal;\r\n}\r\n\r\nbody {\r\n  font-family: 'Satoshi Regular', sans-serif;\r\n}\r\n\r\n.text {\r\n  /* font-weight: 600; */\r\n  /* font-size: 8em; */\r\n  will-change: opacity;\r\n}\r\n\r\n.container {\r\n  display: flex;\r\n  align-items: center;\r\n  /* height: 100%; */\r\n  justify-content: center;\r\n}\r\n\r\n.imagen_banner {\r\n  text-align: center !important;\r\n  align-items: start !important;\r\n  /* min-height: 0px !important; */\r\n}\r\n.boton_editar_imagen {\r\n  margin-left: -83% !important;\r\n}\r\n@media only screen and (max-width: 960px) {\r\n  .boton_editar_imagen {\r\n    margin-left: -83% !important;\r\n  }\r\n  .titulo_banner {\r\n    font-size: 36px !important;\r\n  }\r\n\r\n  .texto_banner {\r\n    font-size: 26px !important;\r\n  }\r\n  .imagen_banner {\r\n    text-align: center !important;\r\n    align-items: start !important;\r\n    min-height: 600px !important;\r\n  }\r\n}\r\n@media only screen and (max-width: 600px) {\r\n  .imagen_banner {\r\n    min-height: 380px !important;\r\n    min-width: 80px !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
