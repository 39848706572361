import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "pages/Presentation/sections/filosofia/style.css";

function HorizontalCard({ image, name, position, title, description }) {
  return (
    <Card sx={{ mt: 1 }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <MKBox
            width="100%"
            p={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              overflow: "hidden",
            }}
          >
            <MKBox
              className="ubicacion_imagen_filosofia"
              component="img"
              src={image}
              alt={image}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <>
            <MKBox p={2} lineHeight={1}>
              <MKTypography variant="h5">{name ? name : ""}</MKTypography>
              <MKTypography
                variant="h6"
                color={position.color ? position.color : ""}
                mb={1}
                sx={{
                  // fontSize: `${tituloFontSize ? tituloFontSize : 25}px`,
                  color: `${position.color ? position.color : "white"}`,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {position.label ? position.label : ""}
              </MKTypography>
              <MKTypography
                variant="h6"
                color={position.color ? position.color : ""}
                mb={1}
                sx={{
                  fontSize: `${position.tamano ? position.tamano : 12}px`,
                  color: `${position.color ? position.color : "white"}`,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {title ? title : ""}
              </MKTypography>
              <MKTypography
                variant="body2"
                color="text"
                sx={{
                  fontSize: `${position.tamanotexto ? position.tamanotexto : 25}px`,
                  color: `${position.colortexto ? position.colortexto : "white"}`,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {description ? description : ""}
              </MKTypography>
            </MKBox>
          </>
        </Grid>
      </Grid>
    </Card>
  );
}

HorizontalCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.shape({
    // color: PropTypes.oneOf([
    //   "primary",
    //   "secondary",
    //   "info",
    //   "success",
    //   "warning",
    //   "error",
    //   "dark",
    //   "light",
    // ]),
    color: PropTypes.string,
    colortexto: PropTypes.string,
    tamano: PropTypes.number,
    tamanotexto: PropTypes.number,
    label: PropTypes.string,
  }),
  description: PropTypes.string,
  title: PropTypes.string,
};

export default HorizontalCard;
