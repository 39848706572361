import { Link } from "react-router-dom";
import "pages/Presentation/sections/footer/menu.css";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useState, useEffect } from "react";
import apiUrl from "domain";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import { LinkedIn } from "@mui/icons-material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import { Upload } from "antd";
import MKInput from "components/MKInput";
import { useMediaQuery, useTheme } from "@mui/material";
import { Drawer, Space } from "antd";
import MKButton from "components/MKButton";
import SoftTypography from "components/SoftTypography";

function DefaultFooterVerde({ content, user_id, roleUser }) {
  const { brand, socials, menus, copyright } = content;
  console.log("copyright", brand);
  console.log("socials", socials);
  console.log("menus", menus);
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);
  const [tituloDireccion, setTituloDireccion] = useState();
  const [direccion, setDireccion] = useState();
  const [ciudad, setCiudad] = useState();
  const [pais, setPais] = useState();
  const [tituloContacto, setTituloContacto] = useState();
  const [tituloLegal, setTituloLegal] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [instagram, setInstagram] = useState();
  const [twitter, setTwitter] = useState();
  const [facebook, setFacebook] = useState();
  const [linkedin, setLinkedin] = useState();
  const [youtube, setYoutube] = useState();
  const [tituloFontSize, setTituloFontSize] = useState();
  const [tituloColor, setTituloColor] = useState();
  const [textoFontSize, setTextoFontSize] = useState();
  const [textoColor, setTextoColor] = useState();
  const [terminos, setTerminos] = useState();
  const [ids, setIds] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [Logo, setLogo] = useState();
  const [newLogo, setNewLogo] = useState();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const fetchTerminos = async () => {
    try {
      const response = await fetch(apiUrl + "/api/terminos/?format=json");
      const data = await response.json();
      setTerminos(data[0].pdf_file);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchQuienes = async () => {
    try {
      const response = await fetch(apiUrl + "/api/footer/?format=json");
      const data = await response.json();
      setLogo(data[0].imagen_footer);
      setIds(data[0].id);
      setTituloDireccion(data[0].titulo_direccion);
      setDireccion(data[0].direccion);
      setCiudad(data[0].ciudad);
      setPais(data[0].pais);
      setTituloContacto(data[0].titulo_contacto);
      setTelefono(data[0].telefono);
      setEmail(data[0].email);
      setInstagram(data[0].instagram);
      setTwitter(data[0].twitter);
      setFacebook(data[0].facebook);
      setLinkedin(data[0].linkedin);
      setYoutube(data[0].youtube);
      setTituloFontSize(data[0].tamano_titulos);
      setTituloColor(data[0].color_titulos);
      setTextoFontSize(data[0].tamano_letras);
      setTextoColor(data[0].color_letras);
      setTituloLegal(data[0].titulo_legal);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchQuienes();
    fetchTerminos();
  }, []);

  const handleCancelEdit = () => {
    setEditMode(false);
    fetchQuienes();
    fetchTerminos();
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    fetchQuienes();
    setEditOpen(false);
  };

  const handleSaveImagenFondo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_direccion", tituloDireccion);
      formData.append("direccion", direccion);
      formData.append("ciudad", ciudad);
      formData.append("pais", pais);
      formData.append("titulo_contacto", tituloContacto);
      formData.append("telefono", telefono);
      formData.append("email", email);
      formData.append("instagram", instagram);
      formData.append("twitter", twitter);
      formData.append("facebook", facebook);
      formData.append("linkedin", linkedin);
      formData.append("youtube", youtube);
      formData.append("tamano_titulos", tituloFontSize);
      formData.append("color_titulos", tituloColor);
      formData.append("tamano_letras", textoFontSize);
      formData.append("color_letras", textoColor);
      formData.append("imagen_footer", newLogo);
      await axios.post(apiUrl + "/api/footer/update/" + ids, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchQuienes();
      setEditOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  return (
    <MKBox id="footer" component="footer" className="alinea_footer">
      &nbsp;<br></br>&nbsp;
      <Container>
        {user_id > 0 &&
          (roleUser === "Administrador" || roleUser === "SuperUser") &&
          (editMode ? (
            <>
              <IconButton aria-label="Cancelar">
                <CancelIcon onClick={handleCancelEdit} sx={{ color: "#FF8C00" }} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                onClick={handleEditOpen}
                aria-label="Editar"
                sx={{
                  backgroundColor: "#FF8C00",
                  "&:hover": {
                    backgroundColor: "#dfa967",
                    boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                  },
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ))}
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={!isDesktop} sx={{ mb: 3 }}> */}
          <Grid item xs={12} md={!isDesktop ? 0 : 4} sx={{ textAlign: "center" }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
              sx={{
                fontSize: `${tituloFontSize ? tituloFontSize : 25}px`,
                color: `${tituloColor ? tituloColor : "white"}`,
              }}
            >
              {tituloDireccion ? tituloDireccion : ""}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  // component="a"
                  // href={href}
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{
                    fontSize: `${textoFontSize ? textoFontSize : 12}px`,
                    color: `${textoColor ? textoColor : "white"}`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {direccion ? direccion : ""}
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  // component="a"
                  // href={href}
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{
                    fontSize: `${textoFontSize ? textoFontSize : 12}px`,
                    color: `${textoColor ? textoColor : "white"}`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {ciudad ? ciudad : ""}
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  // component="a"
                  // href={href}
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{
                    fontSize: `${textoFontSize ? textoFontSize : 12}px`,
                    color: `${textoColor ? textoColor : "white"}`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {pais ? pais : ""}
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={!isDesktop ? 0 : 4} sx={{ textAlign: "center" }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
              sx={{
                fontSize: `${tituloFontSize ? tituloFontSize : 25}px`,
                color: `${tituloColor ? tituloColor : "white"}`,
              }}
            >
              {tituloContacto ? tituloContacto : ""}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  // component="a"
                  // href={href}
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{
                    fontSize: `${textoFontSize ? textoFontSize : 12}px`,
                    color: `${textoColor ? textoColor : "white"}`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {email ? email : ""}
                </MKTypography>
              </MKBox>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  // component="a"
                  // href={href}
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                  sx={{
                    fontSize: `${textoFontSize ? textoFontSize : 12}px`,
                    color: `${textoColor ? textoColor : "white"}`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {telefono ? telefono : ""}
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={!isDesktop ? 0 : 4} sx={{ textAlign: "center" }}>
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
              sx={{
                fontSize: `${tituloFontSize ? tituloFontSize : 25}px`,
                color: `${tituloColor ? tituloColor : "white"}`,
              }}
            >
              {tituloLegal ? tituloLegal : ""}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              <MKBox component="li" p={0} m={0} lineHeight={1.25}>
                <MKTypography
                  component="a"
                  // href={href}
                  href={`${apiUrl}/${
                    terminos
                      ? terminos
                      : "/media/landing/terms_conditions/27022024_Tratamiento_de_datos_personales_Verdeser_SStWUIY.pdf"
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Tratamiento de Datos
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            my: 3,
          }}
        >
          <Link>
            <MKBox component="img" src={`${apiUrl}${Logo}`} alt={Logo} maxWidth="12rem" mb={2} />
          </Link>
        </Grid>
        <Grid
          item
          // xs={6}
          sx={{
            textAlign: "center",
            my: 3,
          }}
        >
          {instagram && (
            <>
              &nbsp;&nbsp;
              <MKTypography
                component="a"
                href={instagram}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
                sx={{
                  textAlign: "center",
                }}
              >
                <InstagramIcon />
              </MKTypography>
            </>
          )}
          {facebook && (
            <>
              &nbsp;&nbsp;
              <MKTypography
                component="a"
                href={facebook}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
              >
                <FacebookIcon />
              </MKTypography>
            </>
          )}
          {twitter && (
            <>
              &nbsp;&nbsp;
              <MKTypography
                component="a"
                href={twitter}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
              >
                <XIcon />
              </MKTypography>
            </>
          )}
          {linkedin && (
            <>
              &nbsp;&nbsp;
              <MKTypography
                component="a"
                href={linkedin}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
              >
                <LinkedIn />
              </MKTypography>
            </>
          )}
          {youtube && (
            <>
              &nbsp;&nbsp;
              <MKTypography
                component="a"
                href={youtube}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
              >
                <YouTubeIcon />
              </MKTypography>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            my: 3,
            fontSize: `${textoFontSize}px`,
            color: textoColor,
          }}
        >
          {copyright}
        </Grid>
      </Container>
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveImagenFondo}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <>
              <Grid item>
                <SoftTypography variant="h6" fontWeight="regular" color="text">
                  &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Color&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="color"
                  value={tituloColor}
                  onChange={(e) => setTituloColor(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Tamaño&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="number"
                  value={tituloFontSize}
                  onChange={(e) => setTituloFontSize(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                <SoftTypography variant="h6" fontWeight="regular" color="text">
                  &nbsp;<br></br>Texto&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Color&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="color"
                  value={textoColor}
                  onChange={(e) => setTextoColor(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Tamaño&nbsp;&nbsp;&nbsp;
                </SoftTypography>
                <MKInput
                  type="number"
                  value={textoFontSize}
                  onChange={(e) => setTextoFontSize(e.target.value)}
                  sx={{
                    width: "60px",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
              &nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;
              <Grid item xs={12} sx={{ mb: 3 }}>
                <MKInput
                  type="text"
                  label="Título"
                  value={tituloDireccion}
                  onChange={(e) => setTituloDireccion(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Dirección"
                  value={direccion}
                  onChange={(e) => setDireccion(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Ciudad"
                  value={ciudad}
                  onChange={(e) => setCiudad(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="País"
                  value={pais}
                  onChange={(e) => setPais(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <MKInput
                  type="text"
                  label="Título"
                  value={tituloContacto}
                  onChange={(e) => setTituloContacto(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Título"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Título"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
              <Upload
                name="imagen"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => setNewLogo(file)}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setNewLogo(info.file.originFileObj);
                  }
                }}
              >
                {newLogo ? (
                  <img src={URL.createObjectURL(newLogo)} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  <img src={`${apiUrl}${Logo}`} alt="avatar" style={{ width: "100%" }} />
                )}
              </Upload>
              &nbsp;<br></br>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <MKInput
                  type="text"
                  label="Instagram"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Facebook"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="X"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="Linkedin"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
                &nbsp;<br></br>
                <MKInput
                  type="text"
                  label="YouTube"
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                  sx={{
                    width: "100%",
                    resize: "none",
                    marginRight: theme.spacing(2),
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
            </>
          </MKBox>
        </div>
      </Drawer>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooterVerde
DefaultFooterVerde.propTypes = {
  roleUser: PropTypes.string,
  user_id: PropTypes.number,
  // content: PropTypes.shape({
  //   copyright: PropTypes.string,
  // }),
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
};

export default DefaultFooterVerde;
