import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import routes from "routes";
import SignIn from "layouts/pages/authentication/sign-in";
import LogoutButton from "pages/LandingPages/SignIn/Logout";
import PropTypes from "prop-types";
import "font.css";
import RestaurarPassword from "pages/LandingPages/SignIn/Restaurar";
import Entrys from "pages/Presentation/sections/blogs/entry";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import RegisterUser from "pages/LandingPages/Register";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? element : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
};

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/login" element={<SignIn />} />
          <Route path="/" element={<Presentation />} />
          <Route path="/blogs/:slug" element={<Entrys />} />
          <Route path="/logout/" element={<LogoutButton />} />
          <Route path="/restore/" element={<RestaurarPassword />} />
          <Route path="/contactanos" element={<ContactUs />} />
          <Route path="/login/" element={<SignIn />} />
          <Route path="/registro" element={<RegisterUser />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
