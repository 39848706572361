import React, { useState, useEffect } from "react";
import apiUrl from "domain";
import NavBar from "pages/Presentation/components/NavBar";
import axios from "axios";
import Proposito from "./sections/proposito";
import Como from "./sections/como";
import Filosofia from "./sections/filosofia";
import InformationPensamientos from "./sections/pensamientos";
import { Fab } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import footerRoutes from "footer.routes";
import DefaultFooterVerde from "./sections/footer/default";
import BannerImagen from "./sections/imagen";
import "pages/Presentation/whatsapp.css";

function Presentation() {
  const [user_id, setUserId] = useState(null);
  const [roleUser, setRoleUser] = useState(null);
  const [userNames, setUserNames] = useState(null);
  const [numero, setNumero] = useState();
  const [texto, setTexto] = useState();

  const fetchWhatsapp = async () => {
    try {
      const response = await fetch(apiUrl + "/api/whatsapp/?format=json");
      const data = await response.json();
      console.log("ddddddddd", data);
      setNumero(data[0].telefono);
      setTexto(data[0].texto);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const getCookieValue = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  const fetchUserProfile = async (token_id) => {
    try {
      const csrftoken = getCookieValue("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/authentication/",
        { token_id },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      setUserId(response.data.user_id);
      setRoleUser(response.data.rol);
      setUserNames(response.data.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    const token_id = getCookieValue("token");
    if (token_id) {
      fetchUserProfile(token_id);
    }
    fetchWhatsapp();
  }, []);

  // Añade un efecto para desplazar la página cuando cambia el hash de la URL
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Desplaza la página si hay un hash al cargar

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const WhatsAppButton = () => {
    const openWhatsApp = () => {
      window.open(`https://api.whatsapp.com/send/?phone=%2B${numero}&text=${texto}`, "_blank");
    };

    return (
      <Fab
        color="info"
        aria-label="whatsapp"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
        onClick={openWhatsApp}
        id="whatsapp-button"
      >
        <WhatsApp fontSize="large" />
      </Fab>
    );
  };

  return (
    <>
      <NavBar
        username={userNames}
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <BannerImagen user_id={user_id ? user_id : 0} roleUser={roleUser ? roleUser : "Usuario"} />
      <WhatsAppButton />
      <Proposito
        id="proposito"
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <Como
        id="valores"
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <Filosofia
        id="filosofia"
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <InformationPensamientos
        id="pensamientos"
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <DefaultFooterVerde
        content={footerRoutes}
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
    </>
  );
}

export default Presentation;
