import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import ReactHtmlParser from "react-html-parser";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";

function HorizontalCardBlogs({ image, name, position, title, description, slug }) {
  // const cleanText = (htmlText) => {
  //   const doc = new DOMParser().parseFromString(htmlText, "text/html");
  //   return doc.body.textContent || "";
  // };
  return (
    <Card component={MuiLink} href={`blogs/${slug}`} target="_blank" sx={{ mt: 1 }}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <MKBox
            width="100%"
            p={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              overflow: "hidden",
            }}
          >
            <MKBox
              component="img"
              src={image}
              alt={name}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <MKBox p={2} lineHeight={1}>
            <MKTypography variant="h5">{name}</MKTypography>
            <MKTypography variant="h6" color={position.color} mb={1}>
              {position.label}
            </MKTypography>
            <MKTypography
              component={MuiLink}
              href={`blogs/${slug}`}
              target="_blank"
              variant="h5"
              color={position.color}
              mb={1}
            >
              {title}
            </MKTypography>
            <MKTypography variant="body2" color="text">
              {/* {ReactHtmlParser(cleanText(description))} */}
              {description}...
            </MKTypography>
            <br></br>
            <MKButton
              component={MuiLink}
              href={`blogs/${slug}`}
              target="_blank"
              rel="noreferrer"
              color="info"
              size="small"
              fullWidth
              sx={{
                backgroundColor: "#FF8C00",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
              }}
            >
              Ver...
            </MKButton>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

HorizontalCardBlogs.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default HorizontalCardBlogs;
