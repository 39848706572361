/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "pages/Presentation/sections/como/style.css";

function RotatingCardFrontLanding({ image, title, subtitle }) {
  return (
    <MKBox
      display="flex"
      flexDirection="column"
      // justifyContent="center"
      justifyContent="flex-end"
      alignContent="center"
      // borderRadius="lg"
      coloredShadow="#626949"
      width="100%"
      position="relative"
      zIndex={2}
      height="400px"
      sx={{
        backgroundImage: () => `url(${image ? image : ""})`,
        // backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
        //   `${linearGradient(
        //     rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
        //     rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
        //   )}, url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
      }}
    >
      <MKBox py={12} px={3} textAlign="center" lineHeight={1}>
        {/* {icon && (
          <MKTypography variant="h2" color="white" my={2}>
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </MKTypography>
        )} */}
        <MKBox
          py={1}
          px={2}
          mt={4}
          mb={-18}
          textAlign="center"
          lineHeight={1}
          style={{ backgroundColor: "rgb(255 140 0 / 83%)", paddingButton: "0px" }}
        >
          <MKTypography variant="h2" color="white" gutterBottom>
            {title ? title : ""}
          </MKTypography>
          <MKTypography variant="h5" color="white" gutterBottom>
            {subtitle ? subtitle : ""}
          </MKTypography>
          {/* <MKTypography variant="body2" color="white" opacity={0.8}>
            {description}&nbsp;
          </MKTypography> */}
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the RotatingCardFrontLanding
RotatingCardFrontLanding.defaultProps = {
  // color: "info",
  icon: "",
};

// Typechecking props for the RotatingCardFrontLanding
RotatingCardFrontLanding.propTypes = {
  // color: PropTypes.oneOf([
  //   "primary",
  //   "secondary",
  //   "info",
  //   "success",
  //   "warning",
  //   "error",
  //   "dark",
  //   "light",
  // ]),
  // color: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  // description: PropTypes.node,
};

export default RotatingCardFrontLanding;
