import React, { useState, useEffect, useRef } from "react";
import "pages/Presentation/sections/como/style.css";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFrontLanding from "./rotatingfront";
import RotatingCardLanding from "./rotatingblack";
import apiUrl from "domain";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import MKButton from "components/MKButton";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme, useMediaQuery } from "@mui/material";
import { Drawer, Space, Upload } from "antd";
import SoftTypography from "components/SoftTypography";
import axios from "axios";
import MKTypography from "components/MKTypography";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MKInput from "components/MKInput";
import PropTypes from "prop-types";
import ColorPickerComponent from "../ColorPickerComponent";

function Como({ user_id, roleUser }) {
  const [CargdsInformation, setCargdsInformation] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editImagen, setEditImagen] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [editedItem, setEditedItem] = useState();
  const [tituloComo, setTituloComo] = useState();
  const [idComo, setidComo] = useState();
  const [imagenComo, setImagenComo] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [cardImagen, setCardImagen] = useState();
  const theme = useTheme();
  const [tituloFontSize, setTituloFontSize] = useState();
  const [tituloColor, setTituloColor] = useState();
  const [fondoColor, setfondoColor] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState("75vh");
  const [newLogo, setNewLogo] = useState();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const mobileView = window.innerWidth < theme.breakpoints.up("lg");

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  /**
   * La función `handleDoubleClick` crea un elemento de entrada para seleccionar archivos de imagen y
   * registra el archivo seleccionado en la consola.
   */
  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      console.log(file);
      setCardImagen(file);
    };
    input.click();
    console.log(cardImagen);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditImagen(false);
  };

  /**
   * La función `fetchPublicaciones` obtiene datos de un punto final API específico y establece varias
   * variables de estado en función de los datos recuperados.
   */
  const fetchPublicaciones = async () => {
    try {
      const response = await fetch(apiUrl + "/api/home/?format=json");
      const data = await response.json();
      setidComo(data[0].id);
      setTituloComo(data[0].titulo_como);
      setImagenComo(data[0].imagen_como);
      setTituloFontSize(data[0].tamano_titulo_como);
      setTituloColor(data[0].color_titulo_como);
      setfondoColor(data[0].color_fondo_titulo_como);
      const img = new Image();
      img.onload = () => {
        setImageHeight(img.height + "px");
      };
      img.src = apiUrl + data.imagen_como;
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  /**
   * La función `fetchCards` obtiene información de la tarjeta desde un punto final API y establece los
   * datos recuperados.
   */
  const fetchCards = async () => {
    try {
      const response = await fetch(apiUrl + "/api/informacion-card/?format=json");
      const data = await response.json();
      setCargdsInformation(data);
    } catch (error) {
      console.error("Error fetching information:", error);
    }
  };

  useEffect(() => {
    fetchPublicaciones();
    fetchCards();
  }, []);

  const handleImagenClick = () => {
    handleEditOpen();
  };

  /**
   * La función `handleSaveImagenFondo` guarda una imagen como parte de los datos de un formulario
   * usando Axios en un punto final API específico.
   */
  const handleSaveImagenFondo = async () => {
    try {
      const formData = new FormData();
      console.log("imagenComo", imagenComo);
      formData.append("imagen", newLogo);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idComo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchPublicaciones();
      setEditImagen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  /**
   * La función `handleSaveTituloComo` guarda los cambios en una imagen de Como con título, tamaño,
   * color y datos de imagen específicos mediante una solicitud POST a un punto final API.
   */
  const handleSaveTituloComo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_como", tituloComo);
      formData.append("tamano_titulo_como", tituloFontSize);
      formData.append("color_titulo_como", tituloColor);
      formData.append("color_fondo_titulo_como", fondoColor);
      formData.append("imagen", newLogo);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idComo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchPublicaciones();
      setEditImagen(false);
      handleEditClose();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleEditCancel = () => {
    setNewLogo();
    fetchPublicaciones();
    setEditOpen(false);
  };

  const handleEdit = (item) => {
    setEditItem(true);
    setEditedItem(item);
    handleOpenDialog();
  };

  /**
   * La función `handleSave` se utiliza para actualizar información enviando una solicitud POST con
   * datos del formulario a un punto final API específico.
   * @param editedItem - El parámetro `editedItem` en la función `handleSave` parece ser un objeto que
   * contiene información sobre un elemento que ha sido editado. Incluye las siguientes propiedades:
   */
  const handleSave = async (editedItem) => {
    if (editedItem !== null) {
      try {
        const formData = new FormData();
        formData.append("titulo", editedItem.titulo);
        formData.append("subtitulo", editedItem.subtitulo);
        formData.append("descripcion", editedItem.descripcion);
        formData.append("url", editedItem.url);
        formData.append("url_blog", editedItem.url_blog);
        formData.append("imagen", cardImagen);
        await axios.post(apiUrl + "/api/informacion/update/" + editedItem.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        handleCloseDialog();
        fetchCards();
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    }
    handleCloseDialog();
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    fetchPublicaciones();
    setEditOpen(false);
  };

  return (
    <MKBox
      id="valores"
      minHeight={imageHeight}
      width="100%"
      sx={{
        position: "relative",
        backgroundImage: `${
          newLogo instanceof File
            ? `url(${URL.createObjectURL(newLogo)})`
            : `url(${apiUrl}${imagenComo})`
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      ref={imageRef}
    >
      &nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;
      {user_id > 0 &&
        (roleUser === "Administrador" || roleUser === "SuperUser") &&
        (editImagen ? (
          <>
            <IconButton aria-label="Cancelar">
              <CancelIcon onClick={handleCancelEdit} sx={{ color: "#ffffff" }} />
            </IconButton>
            <IconButton onClick={handleSaveImagenFondo} aria-label="Guardar">
              <SaveIcon sx={{ color: "#ffffff" }} />
            </IconButton>
          </>
        ) : (
          <IconButton
            className="boton_editar_como"
            onClick={handleImagenClick}
            aria-label="Editar"
            sx={{
              backgroundColor: "#FF8C00",
              "&:hover": {
                backgroundColor: "#dfa967",
                boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
              },
            }}
          >
            <EditIcon />
          </IconButton>
        ))}
      <Grid container justifyContent={"center"} alignItems={"center"}>
        {/* <Grid item xs={12} md={8} sx={{ mb: 6 }} justifyContent={"center"}> */}
        <MKTypography
          variant="h1"
          justifyContent={"center"}
          sx={{
            fontSize: `${isDesktop ? tituloFontSize : tituloFontSize - 15}px`,
            color: tituloColor,
            fontWeight: "bold",
            textAlign: "center",
            // backgroundColor: "#0000007d",
            backgroundColor: fondoColor,
          }}
        >
          <>&nbsp;&nbsp;{tituloComo}&nbsp;&nbsp;</>
        </MKTypography>
        {/* </Grid> */}
      </Grid>
      &nbsp;<br></br>&nbsp;<br></br>
      <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
        {CargdsInformation.map((item) => (
          <Grid key={item.id} item xs={12} lg={4} sx={{ mx: "auto" }} className="ubicacion_card">
            <>
              <RotatingCard>
                <RotatingCardFrontLanding
                  image={item.imagen ? `${apiUrl}${item.imagen}` : bgBack}
                  icon="touch_app"
                  title={item.titulo}
                  subtitle={item.subtitulo}
                />
                <RotatingCardLanding
                  fondo="#FDF6EA"
                  description={item.descripcion}
                  action={{
                    type: "external",
                    route: item.url,
                    label: item.url_blog,
                  }}
                />
              </RotatingCard>
            </>
            {user_id > 0 && (roleUser === "Administrador" || roleUser === "SuperUser") && (
              <>
                <MKBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={-8}
                  width="100%"
                >
                  {editMode && (
                    <>
                      <IconButton
                        onClick={() => handleEdit(item)}
                        aria-label="Editar"
                        sx={{
                          backgroundColor: "#FF8C00",
                          "&:hover": {
                            backgroundColor: "#dfa967",
                            boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <div style={{ flex: 1 }}></div>
                    </>
                  )}
                </MKBox>
              </>
            )}
            {user_id > 0 && (roleUser === "Administrador" || roleUser === "SuperUser") && (
              <>
                <IconButton
                  onClick={() => handleEdit(item)}
                  aria-label="Editar"
                  sx={{
                    backgroundColor: "#FF8C00",
                    "&:hover": {
                      backgroundColor: "#dfa967",
                      boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              </>
            )}
            {CargdsInformation.indexOf(item) < CargdsInformation.length - 1 && (
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            )}
            {mobileView ? (
              window.innerWidth < 768 ? (
                <>
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;
                </>
              ) : window.innerWidth <= 960 ? (
                <>
                  &nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;
                </>
              ) : (
                <>
                  &nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;
                  <br />
                  &nbsp;
                </>
              )
            ) : (
              <>
                &nbsp;
                <br />
                &nbsp;
              </>
            )}
            &nbsp;<br></br>
            &nbsp;<br></br>
            &nbsp;<br></br>
          </Grid>
        ))}
      </Grid>
      {editItem && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md" // Establece el ancho máximo del diálogo
          fullWidth
        >
          {/* Aquí coloca el contenido de tu cuadro de diálogo de edición */}
          <DialogTitle>Editar Elemento</DialogTitle>
          <DialogContent>
            {/* Aquí coloca los campos de edición */}
            {/* {apiUrl}
            {editedItem.imagen} */}
            <MKBox mb={2}>
              <MKBox
                component="img"
                // src={`${apiUrl}${editedDataProposito.imagen_proposito}`}
                // src={`${apiUrl}${editedItem.imagen}`}
                src={
                  editItem
                    ? cardImagen instanceof File
                      ? URL.createObjectURL(cardImagen)
                      : `${apiUrl}${editedItem.imagen}`
                    : `${apiUrl}${editedItem.imagen}`
                }
                onDoubleClick={() => {
                  if (editItem) {
                    handleDoubleClick();
                  }
                }}
                alt={editedItem.titulo}
                sx={{
                  width: "100%",
                  //   maxWidth: "100%",
                  height: "auto",
                  //   maxHeight: "100%",
                  borderRadius: "12px",
                }} // Apply border radius here
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                label="Título"
                value={editedItem.titulo}
                onChange={(e) => setEditedItem({ ...editedItem, titulo: e.target.value })}
                sx={{
                  // overflowY: "auto",
                  width: "100%",
                  resize: "none",
                }}
              />
            </MKBox>
            {/* Otros campos de edición */}
            <MKBox mb={2}>
              <MKInput
                label="Subtítulo"
                value={editedItem.subtitulo}
                onChange={(e) => setEditedItem({ ...editedItem, subtitulo: e.target.value })}
                sx={{
                  // overflowY: "auto",
                  width: "100%",
                  resize: "none",
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                label="Descripción"
                value={editedItem.descripcion}
                onChange={(e) => setEditedItem({ ...editedItem, descripcion: e.target.value })}
                multiline
                minRows={3}
                maxRows={10}
                sx={{
                  // overflowY: "auto",
                  width: "100%",
                  resize: "none",
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                label="Botón"
                value={editedItem.url}
                onChange={(e) => setEditedItem({ ...editedItem, url: e.target.value })}
                sx={{
                  // overflowY: "auto",
                  width: "100%",
                  resize: "none",
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                label="Texto Botón"
                value={editedItem.url_blog}
                onChange={(e) => setEditedItem({ ...editedItem, url_blog: e.target.value })}
                sx={{
                  // overflowY: "auto",
                  width: "100%",
                  resize: "none",
                }}
              />
            </MKBox>
            {/* Otros campos de edición */}
          </DialogContent>
          <DialogActions>
            <MKButton onClick={handleCloseDialog} color="secondary">
              Cancelar
            </MKButton>
            {/* <MKButton onClick={handleSaveChanges} color="info"> */}
            <MKButton onClick={() => handleSave(editedItem)} color="info">
              Guardar
            </MKButton>
          </DialogActions>
        </Dialog>
      )}
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={handleEditCancel}
        footer={
          <Space>
            <MKButton onClick={handleEditCancel}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveTituloComo}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <Grid container justifyContent={"center"}>
              <>
                <Upload
                  name="imagen"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={(file) => setNewLogo(file)}
                  onChange={(info) => {
                    if (info.file.status === "done") {
                      setNewLogo(info.file.originFileObj);
                    }
                  }}
                >
                  {newLogo ? (
                    <img
                      src={URL.createObjectURL(newLogo)}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <img src={`${apiUrl}${imagenComo}`} alt="avatar" style={{ width: "100%" }} />
                  )}
                </Upload>
                <MKBox mb={1}>
                  <SoftTypography variant="h6" fontWeight="regular" color="text">
                    &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color Fondo&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <ColorPickerComponent fondoColor={fondoColor} setFondoColor={setfondoColor} />
                  &nbsp;<br></br>&nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="color"
                    value={tituloColor}
                    onChange={(e) => setTituloColor(e.target.value)}
                    label="Color"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Tamaño&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="number"
                    value={tituloFontSize}
                    onChange={(e) => setTituloFontSize(e.target.value)}
                    label="Tamaño"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Texto&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  &nbsp;<br></br>
                  <MKInput
                    type="text"
                    value={tituloComo}
                    onChange={(e) => setTituloComo(e.target.value)}
                    sx={{
                      width: "100%",
                      resize: "none",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                </MKBox>
              </>
            </Grid>
          </MKBox>
        </div>
      </Drawer>
    </MKBox>
  );
}

Como.propTypes = {
  roleUser: PropTypes.string.isRequired,
  user_id: PropTypes.number,
};

export default Como;
