import React, { useState, useEffect, useRef } from "react";
import "pages/Presentation/sections/como/style.css";
import MKBox from "components/MKBox";
import apiUrl from "domain";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SoftTypography from "components/SoftTypography";
import MKButton from "components/MKButton";
import MuiLink from "@mui/material/Link";
import { Drawer, Space } from "antd";
import { Upload } from "antd";
import MKInput from "components/MKInput";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material";
// import MKTypography from "components/MKTypography";
// import { useSpring, animated } from "@react-spring/web";
import "pages/Presentation/sections/imagen/styles.css";

function BannerImagen({ user_id, roleUser }) {
  const theme = useTheme();
  const [bgImage, setBgImage] = useState(null);
  const [imageHeight, setImageHeight] = useState("75vh");
  const [botonBanner, setBotonBanner] = useState(null);
  const [urlBotonBanner, setUrlBotonBanner] = useState(null);
  const [editImagen, setEditImagen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [newLogo, setNewLogo] = useState();
  const imageRef = useRef(null);
  const [TituloBanner, setTituloBanner] = useState(null);
  const [textoBanner, settextoBanner] = useState(null);
  const [TituloBannerColor, setTituloBannerColor] = useState(null);
  const [TituloBannerTamano, setTituloBannerTamano] = useState(null);
  const [textoBannerColor, settextoBannerColor] = useState(null);
  const [textoBannerTamano, settextoBannerTamano] = useState(null);
  // const [state, toggle] = useState(true);

  // const { x } = useSpring({
  //   from: { x: 0 },
  //   x: state ? 1 : 0,
  //   config: { duration: 1000 },
  // });

  /**
   * La función `fetchBgImage` obtiene una imagen de fondo y datos relacionados de una API y los
   * establece en el estado del componente.
   */
  const fetchBgImage = async () => {
    try {
      const response = await fetch(apiUrl + "/api/banner/?format=json");
      const data = await response.json();
      setBgImage(data.imagen_banner);
      const img = new Image();
      img.onload = () => {
        setImageHeight(img.height + "px");
      };
      img.src = apiUrl + data.imagen_banner;
      setBotonBanner(data.texto_url);
      setUrlBotonBanner(data.url);
      setTituloBanner(data.titulo_banner);
      settextoBanner(data.subtitulo_banner);
      setTituloBannerColor(data.color_titulo);
      setTituloBannerTamano(data.tamano_titulo);
      settextoBannerColor(data.color_subtitulo);
      settextoBannerTamano(data.tamano_subtitulo);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchBgImage();
  }, []);

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleImagenClick = () => {
    handleEditOpen();
  };

  const handleCancelEdit = () => {
    setEditImagen(false);
  };

  const handleEditClose = () => {
    fetchBgImage();
    setEditImagen(false);
    setEditOpen(false);
  };

  /**
   * La función `handleSaveTituloComo` carga una imagen y datos relacionados a un servidor mediante una
   * solicitud POST.
   */
  const handleSaveTituloComo = async () => {
    try {
      const formData = new FormData();
      formData.append("imagen_banner", newLogo);
      formData.append("url", urlBotonBanner);
      formData.append("texto_url", botonBanner);
      formData.append("tamano_titulo", TituloBannerTamano);
      formData.append("color_titulo", TituloBannerColor);
      formData.append("tamano_subtitulo", textoBannerTamano);
      formData.append("color_subtitulo", textoBannerColor);
      formData.append("titulo_banner", TituloBanner);
      formData.append("subtitulo_banner", textoBanner);
      await fetch(apiUrl + "/api/banner/upload/", {
        mode: "no-cors",
        // credentials: "include",
        method: "POST",
        body: formData,
      });
      handleEditClose();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <MKBox
      id="banner"
      className="imagen_banner"
      minHeight={imageHeight}
      width="100%"
      sx={{
        position: "relative",
        display: "grid",
        placeItems: "center",
        // backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: `${
          newLogo instanceof File
            ? `url(${URL.createObjectURL(newLogo)})`
            : `url(${apiUrl}${bgImage ? bgImage : ""})`
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "900px",
      }}
      ref={imageRef}
    >
      &nbsp;<br></br>
      &nbsp;<br></br>
      &nbsp;<br></br>
      {/* <MKBox display="flex" justifyContent="center" mt={20}>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <MKBox
            className="titulo_banner"
            onClick={() => toggle(!state)}
            // variant="h1"
            color="white"
            fontSize={65}
            // mt={20}
            // mb={8}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["60xl"],
              },
              fontWeight: "bold",
              textAlign: "center",
              fontSize: `${TituloBannerTamano ? TituloBannerTamano : 12}px`,
              color: `${TituloBannerColor ? TituloBannerColor : "white"}`,
              lineHeight: "1",
            })}
            textAlign="center" // Añade esta línea para centrar el texto
          >
            <animated.div
              // className={styles.mytitle}
              style={{
                opacity: x.to({ range: [1, 1], output: [10, 10] }),
                scale: x.to({
                  range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                  output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                }),
                fontFamily: "Satoshi Regular",
                fontWeight: "normal",
              }}
            >
              {TituloBanner ? TituloBanner : ""}
            </animated.div>
          </MKBox>
          <MKBox
            // variant="body1"
            className="texto_banner"
            color="white"
            textAlign="center"
            px={{ xs: 6, lg: 12 }}
            // mt={1}
            fontSize={25}
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: `${textoBannerTamano ? textoBannerTamano : 12}px`,
              color: `${textoBannerColor ? textoBannerColor : "white"}`,
            }}
          >
            <animated.div
              // className={styles.mytitle}
              style={{
                opacity: x.to({ range: [1, 1], output: [10, 10] }),
                scale: x.to({
                  range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                  output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                }),
                fontFamily: "Satoshi Regular",
                fontWeight: "normal",
              }}
            >
              {textoBanner ? textoBanner : ""}
            </animated.div>
          </MKBox>
        </Grid>
      </MKBox> */}
      {user_id > 0 &&
        (roleUser === "Administrador" || roleUser === "SuperUser") &&
        (editImagen ? (
          <>
            <IconButton aria-label="Cancelar">
              <CancelIcon onClick={handleCancelEdit} sx={{ color: "#ffffff" }} />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              className="boton_editar_imagen"
              onClick={handleImagenClick}
              aria-label="Editar"
              sx={{
                backgroundColor: "#FF8C00",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        ))}
      <MKBox
        // display="flex"
        // justifyContent="center"
        // mt={10}
        display="flex"
        justifyContent="center"
        position="absolute"
        bottom={20}
        width="100%"
      >
        <>
          <MKButton
            component={MuiLink}
            href={urlBotonBanner ? urlBotonBanner : ""}
            color="primary"
            target="_blank"
            sx={{
              backgroundColor: "#FF8C00",
              boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
              "&:hover": {
                backgroundColor: "#dfa967",
                boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
              },
              fontFamily: "Satoshi Regular",
              fontWeight: "normal",
            }}
          >
            {botonBanner ? botonBanner : ""}
          </MKButton>
        </>
        &nbsp;<br></br>
      </MKBox>
      &nbsp;<br></br>
      &nbsp;<br></br>
      &nbsp;<br></br>
      &nbsp;<br></br>
      &nbsp;<br></br>
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveTituloComo}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <Upload
              name="imagen"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => setNewLogo(file)}
              onChange={(info) => {
                if (info.file.status === "done") {
                  setNewLogo(info.file.originFileObj);
                }
              }}
            >
              {newLogo ? (
                <img src={URL.createObjectURL(newLogo)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <img src={`${apiUrl}${bgImage}`} alt="avatar" style={{ width: "100%" }} />
              )}
            </Upload>
            <Grid item>
              <SoftTypography variant="h6" fontWeight="regular" color="text">
                &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="text"
                value={TituloBanner}
                onChange={(e) => setTituloBanner(e.target.value)}
                sx={{
                  width: "100%",
                  resize: "none",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              &nbsp;<br></br>&nbsp;<br></br>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Color&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="color"
                value={TituloBannerColor}
                onChange={(e) => setTituloBannerColor(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Tamaño&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="number"
                value={TituloBannerTamano}
                onChange={(e) => setTituloBannerTamano(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              <SoftTypography variant="h6" fontWeight="regular" color="text">
                &nbsp;<br></br>Subtítulo&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="text"
                label="Título"
                value={textoBanner}
                onChange={(e) => settextoBanner(e.target.value)}
                sx={{
                  width: "100%",
                  resize: "none",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              &nbsp;<br></br>&nbsp;<br></br>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Color&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="color"
                value={textoBannerColor}
                onChange={(e) => settextoBannerColor(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Tamaño&nbsp;&nbsp;&nbsp;
              </SoftTypography>
              <MKInput
                type="number"
                value={textoBannerTamano}
                onChange={(e) => settextoBannerTamano(e.target.value)}
                sx={{
                  width: "60px",
                  marginRight: theme.spacing(2),
                  backgroundColor: "#ffffff",
                }}
              />
            </Grid>
            <SoftTypography variant="h6" fontWeight="regular" color="text">
              &nbsp;<br></br>Botón&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Texto&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            &nbsp;<br></br>
            <MKInput
              type="text"
              value={botonBanner}
              onChange={(e) => setBotonBanner(e.target.value)}
              sx={{
                backgroundColor: "#FDF6EA",
              }}
            />
            &nbsp;&nbsp;<br></br>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              URL&nbsp;&nbsp;&nbsp;
            </SoftTypography>
            &nbsp;<br></br>
            <MKInput
              type="text"
              value={urlBotonBanner}
              onChange={(e) => setUrlBotonBanner(e.target.value)}
              sx={{
                backgroundColor: "#FDF6EA",
              }}
            />
          </MKBox>
        </>
      </Drawer>
      &nbsp;<br></br>
      &nbsp;<br></br>
      &nbsp;<br></br>
    </MKBox>
  );
}

BannerImagen.propTypes = {
  roleUser: PropTypes.string.isRequired,
  user_id: PropTypes.number,
};

export default BannerImagen;
