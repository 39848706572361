import FacebookLogin from "react-facebook-login";
import { FaFacebookF } from "react-icons/fa";
import apiUrl from "domain";
import apiUrl_admin from "admin_domain";
import React from "react";
import axios from "axios";
import "pages/LandingPages/SignIn/facebook.css";

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/; domain=" + process.env.REACT_APP_COOKIES_URL;
};

class FacebookLoginButton extends React.Component {
  responseFacebook = (response) => {
    console.log("responsess", response);
    axios
      .post(apiUrl + "/api/facebook-login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(response),
      })
      .then((response) => {
        // Manejar la respuesta de la API si es necesario
        console.log("Respuesta de la API:", response);
        const { access, token, user_id, messaje } = response.data;
        localStorage.setItem("token", access);
        sessionStorage.setItem("token", access);
        localStorage.setItem("user_id", user_id);
        document.cookie = `token=${token}; domain=.somosverdeser.com; path=/`;
        setCookie("token", token, 1);
        console.log("messaje", messaje);

        setTimeout(() => {
          window.location.href = `${apiUrl_admin}`;
        }, 2000);
      })
      .catch((error) => {
        // Manejar cualquier error
        console.error("Error al enviar la respuesta a la API:", error);
      }); // Aquí puedes manejar la respuesta de Facebook
  };

  render() {
    return (
      <>
        <FacebookLogin
          appId="217601641410876"
          autoLoad={false}
          fields="name,email,first_name,last_name,picture"
          callback={this.responseFacebook}
          icon={<FaFacebookF sx={{ backgroundColor: "red" }} />}
          textButton=""
          aria-label="Login"
          //cssClass="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineNone MuiTypography-root MuiTypography-body1 css-1o7mxr0-MuiTypography-root-MuiLink-root-MuiTypography-root MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-veqwn4-MuiGrid-root facebook-login-buttonss"
          cssClass="facebook-login-buttonss MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-eimhud-MuiSvgIcon-root"
        />
      </>
    );
  }
}

export default FacebookLoginButton;
