import Container from "@mui/material/Container";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import React, { useState, useEffect } from "react";
import apiUrl from "domain";
import axios from "axios";
// Routes
import { Collapse } from "antd";
import MKTypography from "components/MKTypography";
const { Panel } = Collapse;

function Preguntas() {
  const [preguntas, setPreguntas] = useState([]);
  const fetchPreguntas = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/preguntas/?format=json");
      setPreguntas(response.data);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchPreguntas();
  }, []);

  return (
    <>
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container>
          <MKBox px={3} py={{ xs: 2, sm: 6 }}>
            <MKTypography variant="h4" mb={1}>
              Nuestras preguntas Frecuentes
            </MKTypography>
            <Collapse defaultActiveKey={["1"]}>
              {preguntas.map((item) => (
                <Panel header={item.pregunta} key={item.id}>
                  <p>{item.respuesta}</p>
                </Panel>
              ))}
            </Collapse>
          </MKBox>
        </Container>
      </MKBox>
    </>
  );
}

export default Preguntas;
