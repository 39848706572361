import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "examples/Navbars/DefaultNavbar/font.css";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import apiUrl from "domain";
import apiUrl_home from "admin_domain";
import DefaultNavbarDropdown from "examples/Navbars/DefaultNavbar/DefaultNavbarDropdown";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
// import breakpoints from "assets/theme/base/breakpoints";
import axios from "axios";
import { useTheme } from "@mui/material";
import { Drawer, Space } from "antd";
import MKInput from "components/MKInput";
import { Edit } from "@mui/icons-material";
import { Dialog, DialogContent, Stack } from "@mui/material";
import "examples/Navbars/DefaultNavbar/font.css";

function eliminarCookie(nombre) {
  document.cookie =
    nombre +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
    process.env.REACT_APP_COOKIES_URL;
}

function DefaultNavbar({
  brand,
  routes,
  transparent,
  light,
  action,
  sticky,
  relative,
  center,
  background,
  user_id,
  roleUser,
}) {
  const [dropdown, setDropdown] = useState("");
  const [dropdownEl, setDropdownEl] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState("");
  const [arrowRef, setArrowRef] = useState(null);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [Logo, setLogo] = useState();
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("");
  const theme = useTheme();
  const [tituloFontSize, setTituloFontSize] = useState();
  const [barColor, setBarColor] = useState();
  const [textoColor, settextoColor] = useState();
  const [idComo, setidComo] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const handleLogout = () => {
    openModal();
  };

  const handleLogoutAll = () => {
    fetch(apiUrl + "/api/logout/", {
      method: "POST",
      mode: "no-cors",
      credentials: "include", // Para enviar las cookies de sesión al servidor Django
    })
      .then((response) => {
        console.log("response", response);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        // document.cookie =
        //   "cookie_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
        //   process.env.REACT_APP_COOKIES_URL;

        eliminarCookie("token");
        console.log("Eliminar coockies");
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
        if (response.ok) {
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          // Si la solicitud de cierre de sesión es exitosa, redirigimos a la página de inicio de sesión u otra página de su elección
          window.location.href = "/"; // Redirigir a la página de inicio de sesión después de cerrar la sesión
        } else {
          throw new Error("Error al cerrar sesión");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Aquí puedes manejar el error como desees, como mostrar un mensaje de error al usuario
      });
  };

  const handleEditOpen = () => {
    setEditOpen(true);
    document.body.classList.add("drawer-open");
  };

  const handleEditClose = () => {
    setEditOpen(false);
    document.body.classList.remove("drawer-open");
  };

  /**
   * La función `fetchDatosNavBar` obtiene datos de un punto final API y establece varias propiedades
   * de estilo basadas en los datos recuperados.
   */
  const fetchDatosNavBar = async () => {
    try {
      const response = await fetch(apiUrl + "/api/home/?format=json");
      const data = await response.json();
      setidComo(data[0].id);
      setTituloFontSize(data[0].tamano_letra_navbar);
      setBarColor(data[0].color_fondo_navbar);
      settextoColor(data[0].color_letra_navbar);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  /**
   * La función `handleSaveDatosNavBar` guarda los cambios en el estilo de la barra de navegación
   * utilizando FormData y la solicitud de publicación de Axios.
   */
  const handleSaveDatosNavBar = async () => {
    try {
      const formData = new FormData();
      formData.append("tamano_letra_navbar", tituloFontSize);
      formData.append("color_fondo_navbar", barColor);
      formData.append("color_letra_navbar", textoColor);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idComo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchDatosNavBar();
      setEditOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  /* Es un gancho useEffect de React que se utiliza para manejar el comportamiento
  de desplazamiento en una página web. Configura un detector de eventos para el evento de
  desplazamiento en la ventana y llama a la función `handleScroll` cuando el usuario se desplaza. */
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setShowNavbar(currentScrollY < lastScrollY || currentScrollY < 10); // 50 es un valor de umbral para mostrar el navbar cerca de la parte superior
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  /**
   * La función `fetchLogo` recupera una imagen de logotipo de un punto final API específico y la
   * establece como logotipo.
   */
  const fetchLogo = async () => {
    try {
      const response = await fetch(apiUrl + "/api/logo/?format=json");
      const data = await response.json();
      setLogo(data[0].imagen_banner);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  /* Es un gancho useEffect de React que se utiliza para obtener datos para la barra
  de navegación, establecer el color de fondo de la barra de navegación, obtener un logotipo y
  manejar la visualización de la barra de navegación móvil según el ancho de la ventana. También
  incluye un detector de eventos para cambiar el tamaño de la ventana y actualizar la vista móvil de
  la barra de navegación. El gancho useEffect se ejecuta una vez cuando el componente se monta y
  limpia el detector de eventos cuando el componente se desmonta. */
  useEffect(() => {
    fetchDatosNavBar();
    const navbarElement = document.getElementById("navbar");
    if (navbarElement) {
      const style = window.getComputedStyle(navbarElement);
      setBackgroundColor(style.backgroundColor);
    }
    console.log("backgroundColor", backgroundColor);
    fetchLogo();
    function displayMobileNavbar() {
      // if (window.innerWidth < breakpoints.values.lg) {
      console.log("window.innerWidth", window.innerWidth);
      if (window.innerWidth < 948) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    window.addEventListener("resize", displayMobileNavbar);

    displayMobileNavbar();

    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  /* Es un fragmento de JavaScript que asigna una serie de rutas y representa un
  elemento de la barra de navegación para cada ruta. Utiliza la desestructuración para extraer
  propiedades como nombre, icono, href, ruta y colapso de cada objeto de ruta. El elemento de la
  barra de navegación renderizado es un componente DefaultNavbarDropdown al que se le pasan varios
  accesorios, como nombre, icono, href, ruta, colapso, tamano, onMouseEnter, onMouseLeave, luz y
  fondo. El controlador de eventos onMouseEnter establece el estado desplegable si la ruta tiene una
  propiedad de colapso, y el controlador de eventos onMouseLeave borra el estado desplegable si */
  const renderNavbarItems = routes.map(({ name, icon, href, route, collapse }) => (
    <DefaultNavbarDropdown
      key={name}
      letra={textoColor}
      name={name}
      icon={icon}
      href={href}
      route={route}
      tamano={tituloFontSize}
      collapse={Boolean(collapse)}
      onMouseEnter={({ currentTarget }) => {
        if (collapse) {
          setDropdown(currentTarget);
          setDropdownEl(currentTarget);
          setDropdownName(name);
        }
      }}
      target="_blank"
      onMouseLeave={() => collapse && setDropdown(null)}
      light={light}
      background={background}
    />
  ));

  /* Es una función de JavaScript que toma una serie de rutas y mapas sobre cada
  objeto de ruta para generar una plantilla basada en ciertas condiciones. */
  const renderRoutes = routes.map(({ name, collapse, columns, rowsPerColumn }) => {
    let template;

    if (collapse && columns && name === dropdownName) {
      const calculateColumns = collapse.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rowsPerColumn);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      template = (
        <Grid key={name} container spacing={3} py={1} px={1.5}>
          {calculateColumns.map((cols, key) => {
            const gridKey = `grid-${key}`;
            const dividerKey = `divider-${key}`;

            return (
              <Grid key={gridKey} item xs={12 / columns} sx={{ position: "relative" }}>
                {cols.map((col, index) => (
                  <Fragment key={col.name}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                      mt={index !== 0 ? 2 : 0}
                    >
                      {col.name}
                    </MKTypography>
                    {col.collapse.map((item) => (
                      <MKTypography
                        key={item.name}
                        component={item.route ? Link : MuiLink}
                        to={item.route ? item.route : ""}
                        href={item.href ? item.href : (e) => e.preventDefault()}
                        target={item.href ? "_blank" : ""}
                        rel={item.href ? "noreferrer" : "noreferrer"}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="bold"
                        py={0.625}
                        px={2}
                        sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                          borderRadius: borderRadius.md,
                          cursor: "pointer",
                          transition: "all 300ms linear",

                          "&:hover": {
                            backgroundColor: grey[200],
                            color: dark.main,
                          },
                        })}
                      >
                        {item.name}
                      </MKTypography>
                    ))}
                  </Fragment>
                ))}
                {key !== 0 && (
                  <Divider
                    key={dividerKey}
                    orientation="vertical"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "-4px",
                      transform: "translateY(-45%)",
                      height: "90%",
                    }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      );

      // Render the dropdown menu that should be display as list items
    } else if (collapse && name === dropdownName) {
      template = collapse.map((item) => {
        const linkComponent = {
          component: MuiLink,
          href: item.href,
          target: "_blank",
          rel: "noreferrer",
        };

        const routeComponent = {
          component: Link,
          to: item.route,
        };

        return (
          <MKTypography
            key={item.name}
            {...(item.route ? routeComponent : linkComponent)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            variant="button"
            textTransform="capitalize"
            minWidth={item.description ? "14rem" : "12rem"}
            color={item.description ? "dark" : "text"}
            fontWeight={item.description ? "bold" : "bold"}
            py={item.description ? 1 : 0.625}
            px={2}
            sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
              borderRadius: borderRadius.md,
              cursor: "pointer",
              transition: "all 300ms linear",

              "&:hover": {
                backgroundColor: grey[200],
                color: dark.main,

                "& *": {
                  color: dark.main,
                },
              },
            })}
            onMouseEnter={({ currentTarget }) => {
              if (item.dropdown) {
                setNestedDropdown(currentTarget);
                setNestedDropdownEl(currentTarget);
                setNestedDropdownName(item.name);
              }
            }}
            onMouseLeave={() => {
              if (item.dropdown) {
                setNestedDropdown(null);
              }
            }}
          >
            {item.description ? (
              <MKBox>
                {item.name}
                <MKTypography
                  display="block"
                  variant="button"
                  color="text"
                  fontWeight="bold"
                  sx={{ transition: "all 300ms linear" }}
                >
                  {item.description}
                </MKTypography>
              </MKBox>
            ) : (
              item.name
            )}
            {item.collapse && (
              <Icon fontSize="small" sx={{ fontWeight: "bold", verticalAlign: "middle", mr: -0.5 }}>
                keyboard_arrow_right
              </Icon>
            )}
          </MKTypography>
        );
      });
    }

    return template;
  });

  // Routes dropdown menu
  /* Crea un componente de menú desplegable en JavaScript usando la sintaxis JSX.
  Utiliza el componente Popper con varios accesorios como AnchorEl, open, position, modificadores,
  onMouseEnter y onMouseLeave para controlar el comportamiento del menú desplegable. Dentro del
  componente Popper, representa un componente Grow con TransitionProps y propiedades de estilo. El
  menú desplegable contiene un cuadro con un elemento de tipografía y un ícono para la flecha,
  seguido de otro cuadro que representa las rutas para el menú desplegable. */
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="top-start"
      transition
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null);
          setDropdownName("");
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MKBox borderRadius="lg">
            <MKTypography variant="h1" color="white">
              <Icon ref={setArrowRef} sx={{ mt: -3 }}>
                arrow_drop_up
              </Icon>
            </MKTypography>
            <MKBox shadow="lg" borderRadius="lg" p={2} mt={2}>
              {renderRoutes}
            </MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  );

  // Render routes that are nested inside the dropdown menu routes
  /* Es un fragmento de JavaScript que mapea una serie de rutas y genera componentes
  de ruta anidados en función de ciertas condiciones. Comprueba si una ruta debe contraerse y si
  tiene rutas anidadas. Si la ruta principal coincide con un nombre específico, genera una plantilla
  para las rutas anidadas. La plantilla incluye componentes como enlaces, tipografía e íconos
  basados en las propiedades de las rutas anidadas. El resultado final es un conjunto de componentes
  de ruta anidados que se pueden representar en una interfaz de usuario. */
  const renderNestedRoutes = routes.map(({ collapse, columns }) =>
    collapse && !columns
      ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
          let template;

          if (parentName === nestedDropdownName) {
            template =
              nestedCollapse &&
              nestedCollapse.map((item) => {
                const linkComponent = {
                  component: MuiLink,
                  href: item.href,
                  target: "_blank",
                  rel: "noreferrer",
                };

                const routeComponent = {
                  component: Link,
                  to: item.route,
                };

                return (
                  <MKTypography
                    key={item.name}
                    {...(item.route ? routeComponent : linkComponent)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="button"
                    textTransform="capitalize"
                    minWidth={item.description ? "14rem" : "12rem"}
                    color={item.description ? "dark" : "text"}
                    fontWeight={item.description ? "bold" : "bold"}
                    py={item.description ? 1 : 0.625}
                    px={2}
                    sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                      borderRadius: borderRadius.md,
                      cursor: "pointer",
                      transition: "all 300ms linear",

                      "&:hover": {
                        backgroundColor: grey[200],
                        color: dark.main,

                        "& *": {
                          color: dark.main,
                        },
                      },
                    })}
                  >
                    {item.description ? (
                      <MKBox>
                        {item.name}
                        <MKTypography
                          display="block"
                          variant="button"
                          color="text"
                          fontWeight="bold"
                          sx={{ transition: "all 300ms linear" }}
                        >
                          {item.description}
                        </MKTypography>
                      </MKBox>
                    ) : (
                      item.name
                    )}
                    {item.collapse && (
                      <Icon
                        fontSize="small"
                        sx={{ fontWeight: "bold", verticalAlign: "middle", mr: -0.5 }}
                      >
                        keyboard_arrow_right
                      </Icon>
                    )}
                  </MKTypography>
                );
              });
          }

          return template;
        })
      : null
  );

  // Dropdown menu for the nested dropdowns
  /* Crea un componente de menú desplegable anidado en JavaScript utilizando la
  biblioteca Popper. Está representando un componente Popper con accesorios específicos como
  elemento de anclaje, ubicación, transición, estilo y controladores de eventos para entrar y salir
  del mouse. Dentro del componente Popper, utiliza el componente Crecer para animar el menú
  desplegable. El contenido del menú desplegable se representa mediante la función
  renderNestedRoutes. */
  const nestedDropdownMenu = (
    <Popper
      anchorEl={nestedDropdown}
      popperRef={null}
      open={Boolean(nestedDropdown)}
      placement="right-start"
      transition
      style={{ zIndex: 10 }}
      onMouseEnter={() => {
        setNestedDropdown(nestedDropdownEl);
      }}
      onMouseLeave={() => {
        setNestedDropdown(null);
        setNestedDropdownName("");
        setDropdown(null);
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MKBox ml={2.5} mt={-2.5} borderRadius="lg">
            <MKBox shadow="lg" borderRadius="lg" py={1.5} px={1} mt={2}>
              {renderNestedRoutes}
            </MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  );

  const setCerrarModal = () => {
    setModalOpen(false);
  };

  return (
    <Container
      className={editOpen ? "container-open" : ""}
      sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}
      style={{
        transition: "transform 0.3s ease",
        transform: editOpen ? "translateX(-250px)" : "none",
      }}
    >
      <MKBox
        py={0.01}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? "100%" : "calc(100% - 48px)"}
        borderRadius="xl"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position={relative ? "relative" : "absolute"}
        left={0}
        zIndex={3}
        sx={{
          backgroundColor: barColor,
          opacity: showNavbar ? 0.8 : 0,
          visibility: showNavbar ? "visible" : "hidden",
          transition: "background-color 1s, opacity 1s, visibility 1s 0.5s",
          marginTop: mobileView ? "2px" : "16px",
        }}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKTypography variant="button" fontWeight="bold" color={light ? "dark" : "dark"}>
            {brand && (
              <Link to="/">
                <MKBox
                  className="tamano_imagen_navbar"
                  component="img"
                  src={
                    Logo
                      ? `${apiUrl}${Logo}`
                      : `${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`
                  }
                  to="/"
                  alt="Soft UI Logo"
                  //width={mobileView ? {window.innerWidth < breakpoints.values.lg ? "70%" : "20%"} : "8rem"} //editar tamaño de marca
                  // sx={{
                  //   width: mobileView
                  //     ? window.innerWidth < 768
                  //       ? "70%"
                  //       : window.innerWidth <= 960
                  //       ? "20%"
                  //       : "8rem"
                  //     : "8rem",
                  // }}
                />
              </Link>
            )}
          </MKTypography>
          <MKBox
            color="inherit"
            display={{ xs: "none", lg: "flex" }}
            ml="auto"
            mr={center ? "auto" : 0}
          >
            {user_id > 0 && (roleUser === "Administrador" || roleUser === "SuperUser") && (
              <>
                <Edit onClick={handleEditOpen} sx={{ color: textoColor }} />
              </>
            )}
            {renderNavbarItems}
          </MKBox>
          <MKBox ml={{ xs: "auto", lg: 0 }} className="tamano_boton_navbar">
            {action &&
              (action.type === "internal" ? (
                <MKButton
                  className="tamano_btncerrar_navbar"
                  component={Link}
                  lineHeight={0}
                  to={action.route === "/logout" ? undefined : action.route}
                  variant={
                    action.color === "white" || action.color === "default"
                      ? "contained"
                      : "gradient"
                  }
                  color={action.color ? action.color : "info"}
                  size="small"
                  onClick={action.route === "/logout" ? handleLogout : undefined}
                  // color={textoColor ? textoColor : "white"}
                  sx={{
                    fontWeight: "bold",
                    ml: 1,
                    mr: 0.25,
                    fontSize: mobileView ? `8px ` : `${tituloFontSize - 7}px`,
                    minHeight: "1.5rem",
                  }}
                >
                  {action.label}
                </MKButton>
              ) : (
                <MKButton
                  component="a"
                  className="tamano_btncerrar_navbar"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant={
                    action.color === "white" || action.color === "default"
                      ? "contained"
                      : "gradient"
                  }
                  color={action.color ? action.color : "info"}
                  size="small"
                >
                  {action.label}
                </MKButton>
              ))}
          </MKBox>
          <MKBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </MKBox>
        </MKBox>
        <MKBox
          bgColor={transparent ? "white" : "transparent"}
          shadow={transparent ? "lg" : "none"}
          borderRadius="xl"
          px={transparent ? 2 : 0}
        >
          {mobileView && <DefaultNavbarMobile routes={routes} open={mobileNavbar} />}
        </MKBox>
      </MKBox>
      {dropdownMenu}
      {nestedDropdownMenu}
      <Drawer
        title="Navbar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveDatosNavBar}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <MKInput
              type="number"
              value={tituloFontSize}
              onChange={(e) => setTituloFontSize(e.target.value)}
              label="Tamaño"
              sx={{
                width: "60px",
                marginRight: theme.spacing(2),
                backgroundColor: "#FDF6EA",
              }}
            />
            <MKInput
              type="color"
              value={barColor}
              onChange={(e) => setBarColor(e.target.value)}
              label="Fondo"
              sx={{
                width: "60px",
                marginRight: theme.spacing(2),
                backgroundColor: "#FDF6EA",
              }}
            />
            <MKInput
              type="color"
              value={textoColor}
              onChange={(e) => settextoColor(e.target.value)}
              label="Texto"
              sx={{
                width: "60px",
                marginRight: theme.spacing(2),
                backgroundColor: "#FDF6EA",
              }}
            />
          </MKBox>
        </div>
      </Drawer>
      <Dialog
        open={modalOpen}
        // onClose={setCerrarModal}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        maxWidth="md"
        fullWidth
      >
        <>
          <DialogContent dividers>
            <MKBox p={2}>
              <Stack direction="row" spacing={2}>
                <MKTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;<strong>¿Deseas cerrar la sesión?&nbsp;&nbsp;</strong>
                </MKTypography>
                <MKButton color="info" onClick={handleLogoutAll}>
                  Si
                </MKButton>
                <MKButton color="primary" onClick={setCerrarModal}>
                  No
                </MKButton>
              </Stack>
            </MKBox>
          </DialogContent>
        </>
      </Dialog>
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  brand: `${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`,
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
  background: PropTypes.string,
  roleUser: PropTypes.string,
  user_id: PropTypes.number,
};

export default DefaultNavbar;
