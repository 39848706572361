import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalCardBlogs from "./cards";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme, useMediaQuery } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import apiUrl from "domain";
import axios from "axios";
import MKInput from "components/MKInput";
import { Drawer, Space, Upload } from "antd";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";
import MKButton from "components/MKButton";
// import ColorPickerComponent from "../ColorPickerComponent";

// function truncateText(text, maxLength) {
//   const words = text.split(" ");
//   if (words.length > maxLength) {
//     return words.slice(0, maxLength).join(" ") + "...";
//   }
//   return text;
// }

function ListaBlogs({ user_id, roleUser }) {
  const [TituloTeam, setTituloTeam] = useState(null);
  const [textoEquipo, settextoEquipo] = useState(null);
  const [CargdsEquipo, setCargdsEquipo] = useState([]);
  const [Titulo, setTitulo] = useState(null);
  const [idFilo, setidFilo] = useState(null);
  const [editTitle, setEditTitle] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [tituloFontSize, setTituloFontSize] = useState();
  const [tituloColor, setTituloColor] = useState();
  const [fondoColor, setFondoColor] = useState();
  const [fondoImagen, setFondoImagen] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [newLogo, setNewLogo] = useState();
  const [imageHeight, setImageHeight] = useState("75vh");
  const imageRef = useRef(null);

  const fetchFiloTitulo = async () => {
    try {
      const response = await fetch(apiUrl + "/api/home/?format=json");
      const data = await response.json();
      setidFilo(data[0].id);
      setTitulo(data[0].titulo_blogs);
      setTituloFontSize(data[0].tamano_titulo_blogs);
      setTituloColor(data[0].color_titulo_blogs);
      setFondoColor(data[0].color_fondo_blogs);
      setFondoImagen(data[0].imagen_fondo_blog);
      const img = new Image();
      img.onload = () => {
        setImageHeight(img.height + "px");
      };
      img.src = apiUrl + data[0].imagen_fondo_blog;
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchBgImage = async () => {
    try {
      const response = await fetch(apiUrl + "/api/quienessomos/?format=json");
      const data = await response.json();
      setTituloTeam(data[0].titulo_equipo);
      console.log(TituloTeam);
      settextoEquipo(data[0].descripcion_equipo);
      console.log(textoEquipo);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchCards = async () => {
    try {
      const response = await fetch(apiUrl + "/api/blog-posts/?format=json");
      const data = await response.json();
      setCargdsEquipo(data);
    } catch (error) {
      console.error("Error fetching information:", error);
    }
  };

  useEffect(() => {
    fetchFiloTitulo();
    fetchBgImage();
    fetchCards();
  }, []);

  const handleCancelEdit = () => {
    setEditTitle(false);
  };

  const handleSaveImagenFondo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_blogs", Titulo);
      formData.append("tamano_titulo_blogs", tituloFontSize);
      formData.append("color_titulo_blogs", tituloColor);
      formData.append("color_fondo_blogs", fondoColor);
      formData.append("imagen_fondo_blog", fondoImagen);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idFilo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchFiloTitulo();
      setEditTitle(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleSaveTituloComo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_blogs", Titulo);
      formData.append("tamano_titulo_blogs", tituloFontSize);
      formData.append("color_titulo_blogs", tituloColor);
      formData.append("color_fondo_blogs", fondoColor);
      formData.append("imagen_fondo_blog", fondoImagen);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idFilo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchFiloTitulo();
      setEditTitle(false);
      handleEditClose();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    fetchFiloTitulo();
    setEditOpen(false);
  };

  return (
    <MKBox
      minHeight={imageHeight}
      width="100%"
      sx={{
        position: "relative",
        backgroundImage: `${
          newLogo instanceof File
            ? `url(${URL.createObjectURL(newLogo)})`
            : `url(${apiUrl}${fondoImagen})`
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      ref={imageRef}
      // component="section"
      // py={{ xs: 3, md: 12 }}
      // sx={{
      //   backgroundColor: "#FDF6EA",
      // }}
    >
      <Container>
        {user_id > 0 &&
          (roleUser === "Administrador" || roleUser === "SuperUser") &&
          (editTitle ? (
            <>
              <IconButton aria-label="Cancelar">
                <CancelIcon onClick={handleCancelEdit} sx={{ color: "#FF8C00" }} />
              </IconButton>
              <IconButton onClick={handleSaveImagenFondo} aria-label="Guardar">
                <SaveIcon sx={{ color: "#FF8C00" }} />
              </IconButton>
            </>
          ) : (
            <IconButton
              onClick={handleEditOpen}
              aria-label="Editar"
              sx={{
                backgroundColor: "#FF8C00",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
                marginTop: "100px",
              }}
            >
              <EditIcon />
            </IconButton>
          ))}
        {editTitle ? (
          <>
            <Grid container justifyContent={"center"}>
              <>
                <MKBox mb={1}>
                  <Grid item sx={{ flexGrow: 1 }}>
                    <MKInput
                      value={Titulo}
                      onChange={(e) => setTitulo(e.target.value)}
                      minRows={1}
                      multiline
                      maxRows={10}
                      sx={{
                        overflowY: "auto",
                        width: "100%",
                        resize: "none",
                        backgroundColor: "#FDF6EA",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <MKInput
                      type="number"
                      value={tituloFontSize}
                      onChange={(e) => setTituloFontSize(e.target.value)}
                      label="Tamaño"
                      sx={{
                        width: "60px",
                        marginRight: theme.spacing(2),
                        backgroundColor: "#FDF6EA",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <MKInput
                      type="color"
                      value={tituloColor}
                      onChange={(e) => setTituloColor(e.target.value)}
                      label="Color"
                      sx={{
                        width: "60px",
                        marginRight: theme.spacing(2),
                        backgroundColor: "#FDF6EA",
                      }}
                    />
                  </Grid>
                </MKBox>
                <IconButton onClick={handleSaveTituloComo} aria-label="Guardar">
                  <SaveIcon sx={{ color: "#FDF6EA" }} />
                </IconButton>
              </>
            </Grid>
          </>
        ) : (
          <>
            &nbsp;<br></br>&nbsp;<br></br>
            &nbsp;<br></br>&nbsp;<br></br>
            <Grid container justifyContent={"center"}>
              {/* <Grid item xs={12} md={8} sx={{ mb: 6 }} justifyContent={"center"}> */}
              <MKTypography
                ariant="h1"
                justifyContent={"center"}
                sx={{
                  fontSize: `${isDesktop ? tituloFontSize : tituloFontSize - 15}px`,
                  color: tituloColor,
                  fontWeight: "bold",
                  textAlign: "center",
                  lineHeight: "1",
                }}
              >
                <>{Titulo}</>
              </MKTypography>
              {/* </Grid> */}
            </Grid>
          </>
        )}
        &nbsp;<br></br>
        <>
          <Grid container spacing={3}>
            {CargdsEquipo.map((item) => (
              <Grid key={item.id} item xs={12} lg={6}>
                <MKBox mb={{ xs: 1, lg: 0 }}>
                  <HorizontalCardBlogs
                    image={`${item.imagen}`}
                    name={item.nombre}
                    position={{ color: "info" }}
                    title={item.titulo}
                    slug={item.slug}
                    description={item.introduccion.substring(0, 150)}
                  />
                </MKBox>
              </Grid>
            ))}
          </Grid>
        </>
        &nbsp;<br></br>&nbsp;<br></br>
      </Container>
      <Drawer
        title="Editar Título"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveTituloComo}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <Grid container justifyContent={"center"}>
              <>
                <MKBox mb={1}>
                  <SoftTypography variant="h6" fontWeight="regular" color="text">
                    &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Imagen Fondo&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <Upload
                    name="imagen"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={(file) => setFondoImagen(file)}
                    onChange={(info) => {
                      if (info.file.status === "done") {
                        setNewLogo(info.file.originFileObj);
                      }
                    }}
                  >
                    {newLogo ? (
                      <img
                        src={URL.createObjectURL(newLogo)}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img src={`${apiUrl}${fondoImagen}`} alt="avatar" style={{ width: "100%" }} />
                    )}
                  </Upload>
                  {/* <ColorPickerComponent fondoColor={fondoColor} setFondoColor={setFondoColor} /> */}
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="color"
                    value={tituloColor}
                    onChange={(e) => setTituloColor(e.target.value)}
                    label="Color"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Tamaño&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="number"
                    value={tituloFontSize}
                    onChange={(e) => setTituloFontSize(e.target.value)}
                    label="Tamaño"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Texto&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  &nbsp;<br></br>
                  <MKInput
                    type="text"
                    value={Titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    sx={{
                      width: "100%",
                      resize: "none",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                </MKBox>
              </>
            </Grid>
          </MKBox>
        </div>
      </Drawer>
    </MKBox>
  );
}

ListaBlogs.propTypes = {
  roleUser: PropTypes.string.isRequired,
  user_id: PropTypes.number,
};

export default ListaBlogs;
