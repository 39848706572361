// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Satoshi-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Satoshi Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.app-background {
    background-color: #c91111; /* Cambia el color como prefieras */
}
body {
  font-family: 'Satoshi Regular';
  background-color: #fdf6ea !important; /* Cambia el color a tu preferencia */
  box-sizing: unset !important;
}

@media (max-width: 600px) {
  .container {
    max-width: 100%; /* Cambia el tamaño máximo para dispositivos móviles */
  }
}

#root {
  height: "100vh" !important;
  width: "100vw" !important;
}`, "",{"version":3,"sources":["webpack://./src/font.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,+DAAkD;EAClD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;IACI,yBAAyB,EAAE,mCAAmC;AAClE;AACA;EACE,8BAA8B;EAC9B,oCAAoC,EAAE,qCAAqC;EAC3E,4BAA4B;AAC9B;;AAEA;EACE;IACE,eAAe,EAAE,sDAAsD;EACzE;AACF;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B","sourcesContent":["@font-face {\n  font-family: \"Satoshi Regular\";\n  src: url(\"Satoshi-Regular.ttf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n.app-background {\n    background-color: #c91111; /* Cambia el color como prefieras */\n}\nbody {\n  font-family: 'Satoshi Regular';\n  background-color: #fdf6ea !important; /* Cambia el color a tu preferencia */\n  box-sizing: unset !important;\n}\n\n@media (max-width: 600px) {\n  .container {\n    max-width: 100%; /* Cambia el tamaño máximo para dispositivos móviles */\n  }\n}\n\n#root {\n  height: \"100vh\" !important;\n  width: \"100vw\" !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
