import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiUrl from "domain";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import NavBar from "pages/Presentation/components/NavBar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import bgImages from "assets/images/bg-sign-in-basic.jpeg";
import Switch from "@mui/material/Switch";
import apiUrl_admin from "admin_domain";

function RegisterUser() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [terminos, setterminos] = useState();
  const [message, setMessage] = useState("");
  const [imageHeight, setImageHeight] = useState("75vh");
  const imageRef = useRef(null);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // const [user_id, setUserId] = useState(null);
  // const [roleUser, setRoleUser] = useState(null);
  // const [userNames, setUserNames] = useState(null);

  useEffect(() => {
    const token_id = getCookie("token");
    if (token_id) {
      console.log("token_id", token_id);
      window.location.href = apiUrl_admin;
    }
  }, []);

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      "; path=/; domain=" +
      process.env.REACT_APP_COOKIES_URL;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [bgImage, setBgImage] = useState(null);

  const handleLogin = async (email, password) => {
    try {
      const csrftoken = getCookie("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/login/",
        { email, password },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      const { access, token, user_id, messaje } = response.data;
      localStorage.setItem("token", access);
      sessionStorage.setItem("token", access);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("messaje", messaje);
      document.cookie = `token=${token}; domain=.somosverdeser.com; path=/`;
      setCookie("token", token, 1);
      setTimeout(() => {
        window.location.href = `${apiUrl_admin}`;
      }, 2000);
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setMessage("Usuario o contraseña incorrectos.");
      console.log(message);
    }
  };

  const fetchQuienes = async () => {
    try {
      const response = await fetch(apiUrl + "/api/terminos/?format=json");
      const data = await response.json();
      setterminos(data[0].pdf_file);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const fetchBgImage = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/imagenesfondo/?format=json");
      setBgImage(response.data[0].imagen_contactanos);
      const img = new Image();
      img.onload = () => {
        setImageHeight(img.height + "px");
      };
      img.src = apiUrl + response.data[0].imagen_contactanos;
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchBgImage();
    fetchQuienes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      setErrorMessage("Debes aceptar los términos y condiciones.");
      return;
    }

    try {
      const response = await axios.post(apiUrl + "/api/register/", formData);
      setErrorMessage(""); // Limpiar mensaje de error
      setSuccessMessage(response.data.message); // Establecer el mensaje de éxito
      handleLogin(formData.email, formData.password);
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage(""); // Limpiar mensaje de éxito en caso de error
      setErrorMessage("Error al registrar usuario. Por favor, inténtalo de nuevo."); // Establecer mensaje de error
    }
  };

  return (
    <>
      <NavBar />
      <MKBox
        minHeight={imageHeight}
        width="100%"
        sx={{
          position: "relative",
          backgroundImage: () =>
            `url(${bgImage ? `${apiUrl}/${bgImage}` : `${apiUrl}/${bgImages}`})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        ref={imageRef}
        // component="section"
        // py={{ xs: 3, md: 12 }}
        // sx={{
        //   backgroundColor: "#FDF6EA",
        // }}
      >
        {/* <MKBox
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: () =>
              `url(${bgImage ? `${apiUrl}/${bgImage}` : `${apiUrl}/${bgImages}`})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        > */}
        <MKBox px={1} width="90%" height="100vh" mx="auto" position="relative" zIndex={2}>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3} sx={{ opacity: "0.8" }}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Regístrate
                  </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                  <form onSubmit={handleSubmit}>
                    <MKBox mb={2}>
                      <MKInput
                        type="email"
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="password"
                        name="password"
                        label="Contraseña"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                      />
                    </MKBox>
                    <MKBox mt={2} mb={1}>
                      <label>
                        <Switch checked={termsAccepted} onChange={handleTermsChange} />
                        <MKTypography
                          component={terminos ? Link : "span"}
                          to={terminos ? `${apiUrl}${terminos}` : null}
                          target="_blank"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                          &nbsp;&nbsp;Acepto Tratamiento de datos, ver aquí.
                        </MKTypography>
                      </label>
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                        disabled={!termsAccepted}
                      >
                        Registrarse
                      </MKButton>
                    </MKBox>
                    {errorMessage && (
                      <MKTypography color="error" variant="subtitle2">
                        {errorMessage}
                      </MKTypography>
                    )}
                    {successMessage && (
                      <MKTypography color="success" variant="subtitle2">
                        {successMessage}
                      </MKTypography>
                    )}
                  </form>
                </MKBox>
                <Grid container justifyContent="center" mt={2}>
                  <MKTypography variant="button" color="text">
                    ¿Ya tienes cuenta?&nbsp;
                    <MKTypography
                      component={Link}
                      to="/login"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Inicia sesión
                    </MKTypography>
                  </MKTypography>
                  &nbsp;<br></br>
                  &nbsp;<br></br>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
          <SimpleFooter light />
        </MKBox>
        {/* <SimpleFooter /> */}
      </MKBox>
    </>
  );
}

export default RegisterUser;
