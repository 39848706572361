// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#whatsapp-button {
  background-color: #606336 !important;
  color: #ffffff !important;
  /* right: 140px !important; */
}
@media screen and (max-width: 960px) {
  #whatsapp-button {
    display: block !important;
    bottom: "50px" !important;
    bottom: "50px" !important;
    background-color: #606336 !important;
    /* right: 140px !important; */
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Presentation/whatsapp.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;EACE;IACE,yBAAyB;IACzB,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,6BAA6B;EAC/B;AACF","sourcesContent":["#whatsapp-button {\n  background-color: #606336 !important;\n  color: #ffffff !important;\n  /* right: 140px !important; */\n}\n@media screen and (max-width: 960px) {\n  #whatsapp-button {\n    display: block !important;\n    bottom: \"50px\" !important;\n    bottom: \"50px\" !important;\n    background-color: #606336 !important;\n    /* right: 140px !important; */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
