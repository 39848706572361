// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-open {
  transition: margin-right 0.3s ease;
}
  
.drawer-open {
  margin-right: 1300px; /* Ajusta el valor según el ancho del Drawer */
}

.ant-drawer-mask {
  background: rgb(0 0 0 / 4%) !important;
}

.tamano_imagen_navbar {
  width: 14% !important;
}
.tamano_btncerrar_navbar {
  /* font-size: 10px !important; */
  min-height: 1.5rem !important;
}

@media screen and (max-width: 960px) {
  .tamano_imagen_navbar {
    width: 20% !important;
  }
  .tamano_btncerrar_navbar {
    /* font-size: 10px !important; */
    min-height: 1.5rem !important;
  }
}
@media screen and (max-width: 600px) {
  .tamano_imagen_navbar {
    width: 30% !important;
  }
  .tamano_btncerrar_navbar {
    /* font-size: 10px !important; */
    min-height: 1.5rem !important;
  }
}
@media screen and (max-width: 499px) {
  .tamano_imagen_navbar {
    width: 50% !important;
  }
  .tamano_btncerrar_navbar {
    /* font-size: 4px !important; */
    min-height: 1.5rem !important;
    line-height: 0.9 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/examples/Navbars/DefaultNavbar/font.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,oBAAoB,EAAE,8CAA8C;AACtE;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,gCAAgC;IAChC,6BAA6B;EAC/B;AACF;AACA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,gCAAgC;IAChC,6BAA6B;EAC/B;AACF;AACA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,+BAA+B;IAC/B,6BAA6B;IAC7B,2BAA2B;EAC7B;AACF","sourcesContent":[".container-open {\r\n  transition: margin-right 0.3s ease;\r\n}\r\n  \r\n.drawer-open {\r\n  margin-right: 1300px; /* Ajusta el valor según el ancho del Drawer */\r\n}\r\n\r\n.ant-drawer-mask {\r\n  background: rgb(0 0 0 / 4%) !important;\r\n}\r\n\r\n.tamano_imagen_navbar {\r\n  width: 14% !important;\r\n}\r\n.tamano_btncerrar_navbar {\r\n  /* font-size: 10px !important; */\r\n  min-height: 1.5rem !important;\r\n}\r\n\r\n@media screen and (max-width: 960px) {\r\n  .tamano_imagen_navbar {\r\n    width: 20% !important;\r\n  }\r\n  .tamano_btncerrar_navbar {\r\n    /* font-size: 10px !important; */\r\n    min-height: 1.5rem !important;\r\n  }\r\n}\r\n@media screen and (max-width: 600px) {\r\n  .tamano_imagen_navbar {\r\n    width: 30% !important;\r\n  }\r\n  .tamano_btncerrar_navbar {\r\n    /* font-size: 10px !important; */\r\n    min-height: 1.5rem !important;\r\n  }\r\n}\r\n@media screen and (max-width: 499px) {\r\n  .tamano_imagen_navbar {\r\n    width: 50% !important;\r\n  }\r\n  .tamano_btncerrar_navbar {\r\n    /* font-size: 4px !important; */\r\n    min-height: 1.5rem !important;\r\n    line-height: 0.9 !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
