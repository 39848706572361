import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import SimpleFooter from "examples/Footers/SimpleFooter";
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import "pages/LandingPages/SignIn/login.css";
import apiUrl from "domain";
import apiUrl_admin from "admin_domain";

// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIco from "@mui/icons-material/Twitter";
// import GoogleIcon from "@mui/icons-material/Google";
import MuiLink from "@mui/material/Link";
// import LoginTwitter from "pages/LandingPages/SignIn/X";
// import LoginGoogle from "pages/LandingPages/SignIn/Google";
import axios from "axios";
import MKAlert from "components/MKAlert";
import Logins from "./LoginG";
import FacebookLoginButton from "./Facebook";

function SignInBasic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const [logo, setLogo] = useState();

  const fetchLogo = async () => {
    try {
      const response = await fetch(apiUrl + "/api/logo/?format=json");
      const data = await response.json();
      setLogo(data[0].imagen_banner);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchBgImage = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/imagenesfondo/?format=json");
      console.log("response", response);
      // const data = await response.json();
      setBgImage(response.data[0].imagen_login);
      console.log("bgImage", bgImage);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchLogo();
    fetchBgImage();
    console.log("process.env.COOKIES_URL", process.env.REACT_APP_COOKIES_URL);
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    //document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=" + "localhost";
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      "; path=/; domain=" +
      process.env.REACT_APP_COOKIES_URL;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert("Por favor, ingresa tu correo electrónico y contraseña.");
      return;
    }
    try {
      const csrftoken = getCookie("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/login/",
        { email, password },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      console.log("response.data", response.data);
      const { access, token, user_id, messaje } = response.data;
      localStorage.setItem("token", access);
      sessionStorage.setItem("token", access);
      localStorage.setItem("user_id", user_id);
      document.cookie = `token=${token}; domain=.somosverdeser.com; path=/`;
      setCookie("token", token, 1);
      console.log("messaje", messaje);
      // setMessage(messaje);

      setTimeout(() => {
        window.location.href = `${apiUrl_admin}`;
      }, 2000);

      // console.log("¿response login", response.data);
      // if (response.data) {
      //   localStorage.setItem("token", response.data.token);
      //   localStorage.setItem("user_id", response.data.user_id);
      //   const userId = response.data.user_id;
      //   sessionStorage.setItem("user_id", 1); // Almacena la sesió
      //   // Envía un mensaje a todas las pestañas abiertas en el navegador
      //   window.postMessage({ type: "UPDATE_USER_ID", userId }, "*");
      //   // history("/");
      //   // const sessionValuesString = JSON.stringify(sessionStorage);
      //   window.location.href = `${apiUrl_admin}?user_id=${userId}&token=${response.data.token}`;
      // } else {
      //   // Maneja el error de login
      //   alert("Usuario o contraseña incorrectos");
      // }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setMessage("Usuario o contraseña incorrectos.");
    }
  };

  useEffect(() => {
    let timer;
    if (message) {
      timer = setTimeout(() => {
        setMessage(""); // Esto hará que el mensaje desaparezca después de 5 segundos
      }, 2000);
    }
    return () => clearTimeout(timer); // Limpia el temporizador al desmontar el componente
  }, [message]);

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${apiUrl}/${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} sx={{ opacity: "0.8" }}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKBox
                  className="tamano_imagen_login"
                  component="img"
                  src={
                    logo ? `${apiUrl}/media/landing/logo/LOGOVERDESER-03.png` : `${apiUrl}${logo}`
                  }
                  to="/"
                  alt="Soft UI Logo"
                />
                {/* <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Login
                </MKTypography> */}
                <Grid container spacing={-2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <MKTypography
                      component={MuiLink}
                      // href={`${apiUrl}/accounts/facebook/login/`}
                      variant="body1"
                      color="white"
                    >
                      {/* <FacebookIcon color="inherit" /> */}
                      <FacebookLoginButton />
                    </MKTypography>
                  </Grid>
                  {/* <Grid item xs={2}>
                    <MKTypography
                      component={MuiLink}
                      // href={`${apiUrl}/accounts/twitter/login/`}
                      variant="body1"
                      color="white"
                    >
                      <TwitterIco color="inherit" />
                    </MKTypography>
                  </Grid> */}
                  <Grid item xs={2}>
                    <MKTypography
                      component={MuiLink}
                      // href={`${apiUrl}/accounts/google/login/`}
                      variant="body1"
                      color="white"
                      target="_blank"
                    >
                      {/* <GoogleIcon color="inherit" /> */}
                      <Logins />
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
              {/* <LoginTwitter /> */}
              <MKBox pt={4} pb={3} px={3}>
                <form onSubmit={handleLogin}>
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </MKBox>
                  {message && (
                    <Grid item xs={12}>
                      <MKAlert color="verdeser">{message}</MKAlert>
                    </Grid>
                  )}
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Recordarme
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton type="submit" variant="gradient" color="info" fullWidth>
                      Login
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      ¿No recuerdas tu contraseña?{" "}
                      <MKTypography
                        component={Link}
                        to="/restore/"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Restaurar
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                  {/* <LoginTwitter /> */}
                </form>
              </MKBox>
              {/* <LoginGoogle /> */}
              {/* <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={passwords}
                  onChange={(e) => setPasswords(e.target.value)}
                />
                <button type="submit">Login</button>
              </form> */}
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignInBasic;
