import React from "react";
// import PropTypes from "prop-types";
import apiUrl from "domain";
import SimpleFooter from "examples/Footers/SimpleFooter";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function eliminarCookie(nombre) {
  document.cookie =
    nombre +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
    process.env.REACT_APP_COOKIES_URL;
}

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    fetch(apiUrl + "/api/logout/", {
      method: "POST",
      mode: "no-cors",
      credentials: "include", // Para enviar las cookies de sesión al servidor Django
    })
      .then((response) => {
        console.log("response", response);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        // document.cookie =
        //   "cookie_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
        //   process.env.REACT_APP_COOKIES_URL;

        eliminarCookie("token");
        console.log("Eliminar coockies");
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
        if (response.ok) {
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          // Si la solicitud de cierre de sesión es exitosa, redirigimos a la página de inicio de sesión u otra página de su elección
          window.location.href = "/"; // Redirigir a la página de inicio de sesión después de cerrar la sesión
        } else {
          throw new Error("Error al cerrar sesión");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Aquí puedes manejar el error como desees, como mostrar un mensaje de error al usuario
      });
  }

  render() {
    return (
      <>
        <MKBox
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    ¿Desea Cerrar la sesión?
                  </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      onClick={this.handleLogout}
                      type="submit"
                      variant="gradient"
                      color="info"
                      sx={{
                        right: "-73px",
                      }}
                    >
                      Cerrar sesión
                    </MKButton>
                    {/* <button onClick={this.handleLogout}>Cerrar sesión</button>; */}
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      href="/"
                      type="submit"
                      variant="gradient"
                      color="info"
                      sx={{
                        right: "-90px",
                      }}
                    >
                      Cancelar
                    </MKButton>
                    {/* <button onClick={this.handleLogout}>Cerrar sesión</button>; */}
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
          <SimpleFooter light />
        </MKBox>
      </>
    );
  }
}

export default LogoutButton;
