import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import "pages/Presentation/sections/como/style.css";
// import ReactHtmlParser from "react-html-parser";

function RotatingCardLandingBack({ color, fondo, image, description, action, url }) {
  // const cleanText = (htmlText) => {
  //   const doc = new DOMParser().parseFromString(htmlText, "text/html");
  //   return doc.body.textContent || "";
  // };
  return (
    <>
      <MKBox
        variant={color === "transparent" ? "contained" : "gradient"}
        borderRadius="xl"
        shadow={color === "transparent" ? "none" : "md"}
        p={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        coloredShadow={"#626949"}
        position="absolute"
        width="100%"
        top={0}
        left={0}
        zIndex={5}
        sx={{
          backgroundSize: "cover",
          backfaceVisibility: "hidden",
          transform: "rotateY(180deg)",
          ...(image
            ? {
                backgroundImage: `url(${image})`,
              }
            : {
                backgroundColor: fondo,
              }),
        }}
      >
        <MKBox pt={5} pb={15} px={0} textAlign="center" lineHeight={1}>
          {/* {description.split("\n").map((line, index) => (
            <MKTypography key={index} alignItems="center" variant="h6" color="red" opacity={0.8}>
              {ReactHtmlParser(cleanText(line))}
            </MKTypography>
          ))} */}
          <MKTypography
            alignItems="center"
            variant="h6"
            color="red"
            opacity={0.8}
            sx={{
              fontFamily: "Satoshi Regular",
            }}
          >
            {description} ...
          </MKTypography>
          {action && (
            <MKBox width="50%" mt={4} mb={2} mx="auto">
              {action.type === "external" ? (
                <MKButton
                  component={MuiLink}
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  color="info"
                  size="small"
                  fullWidth
                  sx={{
                    backgroundColor: "#FF8C00",
                    "&:hover": {
                      backgroundColor: "#dfa967",
                      boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                    },
                  }}
                >
                  {action.label}
                </MKButton>
              ) : (
                <MKButton component={Link} to={action.route} color="info" size="small" fullWidth>
                  {action.label}
                </MKButton>
              )}
            </MKBox>
          )}
        </MKBox>
      </MKBox>
      <MKBox
        display="flex"
        justifyContent="center"
        mt={34}
        position="absolute"
        width="100%"
        top={0}
        left={0}
        zIndex={5}
        sx={{
          backgroundSize: "cover",
          backfaceVisibility: "hidden",
          transform: "rotateY(180deg)",
          backgroundColor: "#fff0",
        }}
      >
        <MKButton
          color="primary"
          component={MuiLink}
          href={url}
          target="_blank"
          sx={{
            backgroundColor: "#FF8C00",
            boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
            "&:hover": {
              backgroundColor: "#dfa967",
              boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
            },
            fontFamily: "Satoshi Regular",
          }}
        >
          Artículo completo
        </MKButton>
      </MKBox>
    </>
  );
}

// Setting default props for the RotatingCardLandingBack
RotatingCardLandingBack.defaultProps = {
  color: "info",
};

// Typechecking props for the RotatingCardLandingBack
RotatingCardLandingBack.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
  fondo: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.node,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
};

export default RotatingCardLandingBack;
