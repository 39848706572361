import React from "react";
import routes from "routes";
import SignIn from "layouts/pages/authentication/sign-in";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import LogoutButton from "pages/LandingPages/SignIn/Logout";
// import axios from "axios";
// import apiUrl from "domain";
import PropTypes from "prop-types";

function NavBar({ user_id, roleUser }) {
  const action =
    user_id >= 1
      ? {
          type: "internal",
          route: "/logout",
          label: `Logout`,
          color: "info",
          component: <LogoutButton />,
        }
      : {
          type: "external",
          route: "/login/",
          label: "Login",
          color: "info",
          component: <SignIn />,
        };

  return (
    <>
      <DefaultNavbar routes={routes} action={action} sticky user_id={user_id} roleUser={roleUser} />
    </>
  );
}

NavBar.propTypes = {
  username: PropTypes.string,
  user_id: PropTypes.number,
  roleUser: PropTypes.string,
};

export default NavBar;
