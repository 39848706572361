/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "font.css";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);
console.log("window.innerWidth", window.innerWidth);

root.render(
  <GoogleOAuthProvider clientId="452640359591-20k0hrbnpa24sdi9s81j8a4895b3msqe.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
