import MKBox from "components/MKBox";
import footerRoutes from "footer.routes";
import apiUrl from "domain";
import NavBar from "pages/Presentation/components/NavBar";
import ListaBlogs from "./ListaBlogs";
import bgImage_get from "assets/images/bg-presentation.jpg";
import DefaultFooterVerde from "../footer/default";
import React, { useState, useEffect } from "react";
import axios from "axios";

function PresentationBlog() {
  const [user_id, setUserId] = useState(null);
  const [roleUser, setRoleUser] = useState(null);
  const [userNames, setUserNames] = useState(null);

  const getCookieValue = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  const fetchUserProfile = async (token_id) => {
    try {
      const csrftoken = getCookieValue("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/authentication/",
        { token_id },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      setUserId(response.data.user_id);
      setRoleUser(response.data.rol);
      setUserNames(response.data.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    const token_id = getCookieValue("token");
    if (token_id) {
      fetchUserProfile(token_id);
    }
  }, []);
  return (
    <>
      <NavBar
        username={userNames}
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <MKBox
        id="blogs"
        width="100%"
        sx={{
          position: "relative",
          backgroundImage: `url(${apiUrl}${bgImage_get})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <>
        <ListaBlogs
          id="lista_blogs"
          user_id={user_id ? user_id : 0}
          roleUser={roleUser ? roleUser : "Usuario"}
        />
        <DefaultFooterVerde content={footerRoutes.copyright} />
      </>
    </>
  );
}

export default PresentationBlog;
