import "pages/Presentation/sections/pensamientos/style.css";
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ReviewCard from "./cards";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/splide/dist/css/splide.min.css";
import apiUrl from "domain";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import MKButton from "components/MKButton";
import axios from "axios";
import { useMediaQuery, useTheme } from "@mui/material";
import bgBack from "assets/images/pensamiento.jpg";
import MKInput from "components/MKInput";
import RotatingCard from "examples/Cards/RotatingCard";
import MuiLink from "@mui/material/Link";
import { Drawer, Space, Upload } from "antd";
import SoftTypography from "components/SoftTypography";
import RotatingCardLandingBack from "./back";
import PropTypes from "prop-types";
import ColorPickerComponent from "../ColorPickerComponent";

function InformationPensamientos({ user_id, roleUser }) {
  const [reviews, setReviews] = useState([]);
  const [Titulo, setTitulo] = useState(null);
  const [imagenComoFondo, setImagenComoFondo] = useState();
  const [idPensa, setidPensa] = useState();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [tituloFontSize, setTituloFontSize] = useState();
  const [tituloColor, setTituloColor] = useState();
  const [fondoColor, setfondoColor] = useState();
  const [newLogo, setNewLogo] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [imageHeight, setImageHeight] = useState("75vh");
  const imageRef = useRef(null);

  const fetchBgImage = async () => {
    try {
      const response = await fetch(apiUrl + "/api/home/?format=json");
      const data = await response.json();
      setidPensa(data[0].id);
      setTitulo(data[0].titulo_pensamiento);
      setImagenComoFondo(data[0].imagen_pensamiento);
      setTituloFontSize(data[0].tamano_titulo_pensamiento);
      setTituloColor(data[0].color_titulo_pensamiento);
      setfondoColor(data[0].color_fondo_titulo_pensamiento);
      const img = new Image();
      img.onload = () => {
        setImageHeight(img.height + "px");
      };
      img.src = apiUrl + data.imagen_pensamiento;
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchBlogPost = async () => {
    fetch(apiUrl + "/api/blog-posts/?format=json")
      .then((response) => response.json())
      .then((data) => setReviews(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    fetchBgImage();
    fetchBlogPost();
  }, []);

  const handleImagenClick = () => {
    handleEditOpen();
  };

  const handleSaveTituloComo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_pensamiento", Titulo);
      formData.append("imagen_pensamiento", newLogo);
      formData.append("tamano_titulo_pensamiento", tituloFontSize);
      formData.append("color_titulo_pensamiento", tituloColor);
      formData.append("color_fondo_titulo_pensamiento", fondoColor);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idPensa, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchBgImage();
      handleEditClose();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setNewLogo();
    fetchBgImage();
    setEditOpen(false);
  };

  return (
    <MKBox
      id="pensamientos"
      minHeight={imageHeight}
      width="100%"
      sx={{
        position: "relative",
        backgroundImage: `${
          newLogo instanceof File
            ? `url(${URL.createObjectURL(newLogo)})`
            : `url(${apiUrl}${imagenComoFondo})`
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      ref={imageRef}
    >
      <Container className="container_cards">
        &nbsp;
        <br />
        {user_id >= 1 && (roleUser === "Administrador" || roleUser === "SuperUser") && (
          <IconButton
            onClick={handleImagenClick}
            color="info"
            variant="gradient"
            sx={{
              position: "static",
              bottom: "80px",
              right: "8px",
              backgroundColor: "#FF8C00",
              "&:hover": {
                backgroundColor: "#dfa967",
                boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
              },
            }}
            aria-label="Editar"
          >
            <EditIcon />
          </IconButton>
        )}
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <MKTypography
            variant="h1"
            justifyContent={"center"}
            sx={{
              fontSize: `${isDesktop ? tituloFontSize : tituloFontSize - 15}px`,
              color: tituloColor,
              fontWeight: "bold",
              textAlign: "center",
              backgroundColor: fondoColor,
            }}
          >
            <>&nbsp;&nbsp;{Titulo ? Titulo : ""}&nbsp;&nbsp;</>
          </MKTypography>
        </Grid>
        &nbsp;
        <br />
        &nbsp;
        <br />
        <MKBox>
          <>
            &nbsp;
            <br />
            &nbsp;
            <br />
            <Grid item xs={12} className="splide_tamano_pensamiento">
              <Splide
                options={{
                  type: "loop",
                  perPage: isDesktop ? 4 : 1,
                  autoplay: true,
                }}
                aria-label="React Splide Example"
                sx={{
                  width: "calc(10%)",
                  backgroundColor: "#ffffff",
                  textAlign: "center",
                }}
              >
                {reviews.length > 0 &&
                  reviews.map((review) => (
                    <SplideSlide
                      className="border_card"
                      key={review.id}
                      sx={{
                        backgroundColor: "red",
                      }}
                    >
                      <RotatingCard>
                        <Grid item xs={12} sm={6} md={3}>
                          <ReviewCard
                            className="card_tamano"
                            name={review.titulo}
                            image={
                              review.autor && review.autor.avatar != null
                                ? review.autor.avatar
                                : bgBack
                            }
                            color="light"
                            review={review.entradilla}
                            rating={review.promedio_calificacion}
                            url={review.slug}
                            autor={`${review.autor.user.first_name} ${review.autor.user.last_name}`}
                          />
                        </Grid>
                        <RotatingCardLandingBack
                          fondo="#FDF6EA"
                          description={review.introduccion.substring(0, 150)}
                          url={`blogs/${review.slug}`}
                        />
                      </RotatingCard>
                    </SplideSlide>
                  ))}
              </Splide>
            </Grid>
          </>
        </MKBox>
        &nbsp;
        <br />
        <MKBox display="flex" justifyContent="center" className="boton_pensamientos">
          <MKButton
            color="primary"
            component={MuiLink}
            href="/blogs"
            target="_blank"
            sx={{
              backgroundColor: "#FF8C00",
              boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
              "&:hover": {
                backgroundColor: "#dfa967",
                boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
              },
              fontFamily: "Satoshi Regular",
            }}
          >
            Ver mas pensamientos verdes
          </MKButton>
        </MKBox>
        &nbsp;
        <br />
        &nbsp;
        <br />
      </Container>
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveTituloComo}>
              Guardar
            </MKButton>
          </Space>
        }
      >
        <MKBox px={1}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Título:
          </SoftTypography>
          <MKInput
            type="text"
            value={Titulo || ""}
            onChange={(e) => setTitulo(e.target.value)}
            placeholder="Titulo"
            fullWidth
          />
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Color de fondo:
          </SoftTypography>
          <ColorPickerComponent value={fondoColor} onChange={setfondoColor} />
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Tamaño del título:
          </SoftTypography>
          <MKInput
            type="number"
            value={tituloFontSize || ""}
            onChange={(e) => setTituloFontSize(e.target.value)}
            placeholder="Tamaño del Titulo"
            fullWidth
          />
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Color del título:
          </SoftTypography>
          <ColorPickerComponent value={tituloColor} onChange={setTituloColor} />
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Imagen:
          </SoftTypography>
          <Upload.Dragger
            multiple={false}
            customRequest={({ file, onSuccess }) => {
              setNewLogo(file);
              onSuccess("ok");
            }}
            showUploadList={{ showRemoveIcon: false }}
            accept=".jpeg,.jpg,.png,.gif"
          >
            <p className="ant-upload-drag-icon">
              <i className="fa fa-picture-o" style={{ fontSize: "24px" }} />
            </p>
            <p className="ant-upload-text">
              Haga clic o arrastre el archivo a esta área para cargarlo
            </p>
          </Upload.Dragger>
        </MKBox>
      </Drawer>
    </MKBox>
  );
}

InformationPensamientos.propTypes = {
  user_id: PropTypes.number,
  roleUser: PropTypes.string,
};

export default InformationPensamientos;
