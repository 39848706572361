import RegisterUser from "pages/LandingPages/Register";
import apiUrl_admin from "admin_domain";
import React from "react";
import PresentationBlog from "pages/Presentation/sections/blogs";

function getCookieValue(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}
const token = getCookieValue("token");

const routes = [
  {
    name: "Blogs",
    route: "/blogs",
    component: <PresentationBlog />,
    key: 4,
  },
  token
    ? {
        name: "Perfil",
        route: `${apiUrl_admin}`,
        key: 6,
      }
    : {
        name: "Unete",
        route: "/registro",
        component: <RegisterUser />,
        key: 7,
      },
];

export default routes;
