// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ubicacion_imagen_filosofia {
  height: 100% !important; 
}

@media only screen and (max-width: 576px) {
  .ubicacion_imagen_filosofia {
    height: 104% !important; 
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Presentation/sections/filosofia/style.css"],"names":[],"mappings":";AACA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":["\n.ubicacion_imagen_filosofia {\n  height: 100% !important; \n}\n\n@media only screen and (max-width: 576px) {\n  .ubicacion_imagen_filosofia {\n    height: 104% !important; \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
