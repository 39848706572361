import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import "pages/Presentation/sections/pensamientos/style.css";
import "@splidejs/splide/dist/css/splide.min.css";

function ReviewCard({ color, image, name, date, review, rating, autor }) {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    let stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Icon key={i} color="info">
          star
        </Icon>
      );
    }
    if (halfStar) {
      stars.push(
        <Icon key={stars.length} color="info">
          star_half
        </Icon>
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Icon key={stars.length}>star_outline</Icon>);
    }

    return stars;
  };

  return (
    <>
      <MKBox
        variant={color === "transparent" ? "contained" : "gradient"}
        borderRadius="sm"
        shadow={color === "transparent" ? "none" : "md"}
        p={3}
      >
        <MKBox lineHeight={1}>
          <>
            <MKTypography
              display="block"
              variant={image ? "h7" : "h8"}
              fontWeight="bold"
              color={color === "transparent" || color === "light" ? "dark" : "white"}
              mb={0.5}
              sx={{
                fontFamily: "Satoshi Regular",
              }}
            >
              {name ? name : ""}
            </MKTypography>
            <MKTypography
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              lineHeight={1}
              color={color === "transparent" || color === "light" ? "text" : "white"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {date ? date : ""}
            </MKTypography>
          </>
        </MKBox>
        <MKTypography
          variant="body2"
          color={color === "transparent" || color === "light" ? "text" : "white"}
          my={4}
        >
          &quot;{review}&quot;
        </MKTypography>
        <MKTypography
          variant="h4"
          color={color === "transparent" || color === "light" ? "text" : "white"}
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 0.375,
            "& .material-icons-round": {
              ml: -0.375,
            },
          }}
        >
          &nbsp;<br></br>&nbsp;
          {image && (
            <MKAvatar
              src={image}
              alt={name}
              variant="rounded"
              size="lg"
              shadow="md"
              sx={{ mt: 0, mb: 1 }}
            />
          )}
          &nbsp;
          <MKBox display="flex" flexDirection="column" alignItems="left" sx={{ mb: 1 }}>
            {autor && (
              <MKTypography
                variant="h6"
                color="textPrimary"
                sx={{ mb: 0, fontSize: "14px", fontFamily: "Satoshi Regular" }}
              >
                {autor}
              </MKTypography>
            )}
            <MKTypography
              variant="h4"
              color={color === "transparent" || color === "light" ? "text" : "white"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {renderStars(rating)}
            </MKTypography>
          </MKBox>
          &nbsp;&nbsp;
        </MKTypography>
      </MKBox>
    </>
  );
}

// Setting default values for the props of ReviewCard
ReviewCard.defaultProps = {
  color: "transparent",
  image: "",
  autor: "",
};

// Typechecking props for the ReviewCard
ReviewCard.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  review: PropTypes.string,
  rating: PropTypes.oneOf([
    0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2,
    2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4,
    4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5,
  ]),
  autor: PropTypes.string,
};

export default ReviewCard;
