import "pages/Presentation/sections/blogs/styles.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import NavBar from "pages/Presentation/components/NavBar";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState, useEffect, useRef } from "react";
import apiUrl from "domain";
import footerRoutes from "footer.routes";
import { useParams } from "react-router-dom";
import { Image } from "antd";
import Comentarios from "./comentarios";
import MKBadge from "components/MKBadge";
import Rating from "@mui/material/Rating";
import DefaultFooterVerde from "../footer/default";
import { useMediaQuery, useTheme } from "@mui/material";
import HorizontalCardAutor from "./cardsAutor";
import axios from "axios";

function Entrys() {
  const { slug } = useParams();
  const [Titulo, setTitulo] = useState(null);
  const [Contenido, setContenido] = useState(null);
  const [Categoria, setCategoria] = useState(null);
  const [Imagen, setImagen] = useState(null);
  const [postId, setPostId] = useState();
  const [promedio, setPromedio] = useState(null);
  const [autor, setAutor] = useState(null);
  const [fondoImagen, setFondoImagen] = useState();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [imageHeight, setImageHeight] = useState("75vh");
  const imageRef = useRef(null);
  const [user_id, setUserId] = useState(null);
  const [roleUser, setRoleUser] = useState(null);
  const [userNames, setUserNames] = useState(null);

  const getCookieValue = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  const fetchUserProfile = async (token_id) => {
    try {
      const csrftoken = getCookieValue("csrftoken");
      const response = await axios.post(
        apiUrl + "/api/authentication/",
        { token_id },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      setUserId(response.data.user_id);
      setRoleUser(response.data.rol);
      setUserNames(response.data.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    const token_id = getCookieValue("token");
    if (token_id) {
      fetchUserProfile(token_id);
    }
  }, []);

  const fetchBlog = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/posts/${slug}?format=json`);
      const data = await response.json();
      setPostId(data[0].id);
      setTitulo(data[0].titulo);
      setContenido(data[0].contenido);
      setCategoria(data[0].categoria);
      setImagen(data[0].imagen);
      setPromedio(data[0].promedio_calificacion);
      setAutor(data[0].autor);
      console.log("autor", autor);
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  const fetchFiloTitulo = async () => {
    try {
      const response = await fetch(apiUrl + "/api/home/?format=json");
      const data = await response.json();
      setFondoImagen(data[0].imagen_fondo_blog);
      const img = new Image();
      img.onload = () => {
        setImageHeight(img.height + "px");
      };
      img.src = apiUrl + data[0].imagen_fondo_blog;
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchFiloTitulo();
    fetchBlog();
  }, [slug]);

  if (!Titulo) {
    return <MKBox>Cargando...</MKBox>;
  }

  const renderParagraph = (paragraph, index) => {
    if (paragraph.trim() !== "") {
      return <div key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />;
    } else {
      let j = index + 1;
      while (j < Contenido.length && Contenido[j].trim() === "") {
        j++;
      }
      if (j > index + 1) {
        return <p key={index}>&nbsp;</p>;
      } else {
        return <br key={index} />;
      }
    }
  };

  const renderContent = () => {
    return Contenido.split("\n").map((paragraph, index) => {
      return renderParagraph(paragraph, index);
    });
  };

  return (
    <>
      <NavBar
        username={userNames}
        user_id={user_id ? user_id : 0}
        roleUser={roleUser ? roleUser : "Usuario"}
      />
      <MKBox
        minHeight={imageHeight}
        width="100%"
        sx={{
          position: "relative",
          backgroundImage: `${`url(${apiUrl}${fondoImagen})`}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        ref={imageRef}
      >
        <Container sx={{ backgroundColor: "#ffffff" }}>
          {!isDesktop && (
            <>
              <div style={{ height: "3rem" }} />
            </>
          )}
          <div style={{ height: "3rem" }} />
          &nbsp;<br></br>&nbsp;<br></br>
          <Grid container item xs={12} lg={10} sx={{ mx: "auto" }} className="counter-container">
            <Grid container alignItems="center" direction="column" sx={{ textAlign: "center" }}>
              <MKTypography
                variant="h1"
                sx={{
                  fontSize: { xs: "2rem", md: "5rem" },
                  fontFamily: "Satoshi Regular",
                  fontWeight: "normal",
                }}
              >
                {Titulo}
              </MKTypography>
              <div style={{ height: "1rem" }} />
              <Image
                style={{ maxWidth: "100%", height: "auto" }}
                src={`${apiUrl}${Imagen}`}
                alt={Imagen}
                className="no-preview"
              />
              <MKTypography color="text" mb={0} component="legend">
                Calificación&nbsp;
              </MKTypography>
              <Rating
                name="hover-feedback"
                value={promedio}
                precision={0.2}
                size="large"
                readOnly
              />
              <MKTypography variant="body2" color="text" mb={8}>
                Categorías:&nbsp;
                {Categoria.map((cat, index) => (
                  <MKBadge
                    key={index}
                    className="categorias_class"
                    variant="contained"
                    color="#ff8c0085"
                    badgeContent={`${cat.nombre}`}
                    container
                    sx={{ mb: 2, color: "#f2f2f2" }}
                  />
                ))}
              </MKTypography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              {renderContent()}
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKBox mb={{ xs: 1, lg: 0 }}>
                <HorizontalCardAutor
                  image={
                    autor.avatar
                      ? `${apiUrl}${autor.avatar}`
                      : `${apiUrl}/media/landing/equipo/persona2.jpg`
                  }
                  name={`${autor.user.first_name} ${autor.user.last_name}`}
                  instagram={autor.instagram_url}
                  twitter={autor.twitter_url}
                  linkedin={autor.linkedin_url}
                  facebook={autor.facebook_url}
                />
              </MKBox>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={10}
            sx={{ mx: "auto", mt: 4 }}
            className="counter-container"
          >
            <MKTypography
              variant="body1"
              color="text"
              mb={8}
              style={{
                margin: 0,
                color: "#000000",
              }}
            >
              Deja tus comentarios
            </MKTypography>
            <Grid container alignItems="center">
              <Comentarios postId={postId} />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <DefaultFooterVerde content={footerRoutes} />
    </>
  );
}

export default Entrys;
