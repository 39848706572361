// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facebook-login-buttonss {
    padding: 0; /* Remove default padding */
    border: none; /* Remove default border */
    background-color: #ff8c00 !important; /* Make background transparent */
    color: #ffffff !important;
    border: none;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/LandingPages/SignIn/facebook.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,2BAA2B;IACvC,YAAY,EAAE,0BAA0B;IACxC,oCAAoC,EAAE,gCAAgC;IACtE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".facebook-login-buttonss {\n    padding: 0; /* Remove default padding */\n    border: none; /* Remove default border */\n    background-color: #ff8c00 !important; /* Make background transparent */\n    color: #ffffff !important;\n    border: none;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
