import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalCard from "./cards";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import apiUrl from "domain";
import axios from "axios";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import { Drawer, Space } from "antd";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

function Filosofia({ user_id, roleUser }) {
  const [CargdsEquipo, setCargdsEquipo] = useState([]);
  const [Titulo, setTitulo] = useState(null);
  const [idFilo, setidFilo] = useState(null);
  const [editTitle, setEditTitle] = useState(false);
  const [editItema, setEditItema] = useState(false);
  const [editItemaData, setEditItemaData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [cardImagen, setCardImagen] = useState();
  const theme = useTheme();
  const [tituloFontSize, setTituloFontSize] = useState();
  const [tituloColor, setTituloColor] = useState();
  const [fondoColor, setFondoColor] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [tituloFontCardSize, setTituloFontCardSize] = useState();
  const [tituloColorCard, setTituloColorCard] = useState();
  const [textoFontCardSize, setTextoFontCardSize] = useState();
  const [TextoColorCard, setTextoColorCard] = useState();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  /**
   * La función `handleDoubleClick` crea un elemento de entrada para seleccionar archivos de imagen y
   * registra el archivo seleccionado en la consola.
   */
  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      console.log(file);
      setCardImagen(file);
    };
    input.click();
    console.log(cardImagen);
  };

  /**
   * La función `fetchFiloTitulo` obtiene datos de un punto final API y establece varias propiedades en
   * función de los datos de respuesta.
   */
  const fetchFiloTitulo = async () => {
    try {
      const response = await fetch(apiUrl + "/api/home/?format=json");
      const data = await response.json();
      setidFilo(data[0].id);
      setTitulo(data[0].titulo_filosofia);
      setTituloFontSize(data[0].tamano_titulo_filosofia);
      setTituloColor(data[0].color_titulo_filosofia);
      setFondoColor(data[0].color_fondo_filosofia);
      setTituloFontCardSize(data[0].tamano_titulo_card_filosofia);
      setTituloColorCard(data[0].color_titulo_card_filosofia);
      setTextoFontCardSize(data[0].tamano_texto_carde_filosofia);
      setTextoColorCard(data[0].color_texto_card_filosofia);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  /**
   * La función `fetchCards` obtiene datos de un punto final API específico y establece los datos
   * recuperados en una variable `CargdsEquipo`.
   */
  const fetchCards = async () => {
    try {
      const response = await fetch(apiUrl + "/api/filosofia/?format=json");
      const data = await response.json();
      setCargdsEquipo(data);
    } catch (error) {
      console.error("Error fetching information:", error);
    }
  };

  useEffect(() => {
    fetchFiloTitulo();
    fetchCards();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleEditItem = (item) => {
    setEditItema(true);
    setEditItemaData(item);
    handleOpenDialog();
  };

  const handleCancelEdit = () => {
    setEditTitle(false);
    fetchFiloTitulo();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * La función `handleSaveImagenFondo` guarda los cambios en la configuración del fondo de la imagen
   * usando FormData y Axios en una aplicación React.
   */
  const handleSaveImagenFondo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_filosofia", Titulo);
      formData.append("tamano_titulo_filosofia", tituloFontSize);
      formData.append("color_titulo_filosofia", tituloColor);
      formData.append("color_fondo_filosofia", fondoColor);
      formData.append("tamano_titulo_card_filosofia", tituloFontCardSize);
      formData.append("color_titulo_card_filosofia", tituloColorCard);
      formData.append("tamano_texto_carde_filosofia", textoFontCardSize);
      formData.append("color_texto_card_filosofia", TextoColorCard);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idFilo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchFiloTitulo();
      setEditTitle(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  /**
   * La función `handleSaveTituloComo` envía datos actualizados a un punto final API para guardar
   * cambios relacionados con el diseño de un título de filosofía.
   */
  const handleSaveTituloComo = async () => {
    try {
      const formData = new FormData();
      formData.append("titulo_filosofia", Titulo);
      formData.append("color_fondo_filosofia", fondoColor);
      formData.append("tamano_titulo_filosofia", tituloFontSize);
      formData.append("tamano_titulo_card_filosofia", tituloFontCardSize);
      formData.append("color_titulo_card_filosofia", tituloColorCard);
      formData.append("tamano_texto_carde_filosofia", textoFontCardSize);
      formData.append("color_texto_card_filosofia", TextoColorCard);
      await axios.post(apiUrl + "/api/como/imagen/update/" + idFilo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fetchFiloTitulo();
      setEditTitle(false);
      handleEditClose();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleSave = async (editedItem) => {
    if (editedItem !== null) {
      try {
        const formData = new FormData();
        formData.append("titulo", editedItem.titulo);
        formData.append("descripcion", editedItem.descripcion);
        formData.append("imagen", cardImagen);
        await axios.post(apiUrl + "/api/filosofia/update/" + editedItem.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        handleCloseDialog();
        fetchCards();
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    }
    handleCloseDialog();
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    fetchFiloTitulo();
    setEditOpen(false);
  };

  return (
    <MKBox
      id="filosofia"
      component="section"
      py={{ xs: 3, md: 12 }}
      sx={{
        backgroundColor: fondoColor,
      }}
    >
      <Container>
        {user_id > 0 &&
          (roleUser === "Administrador" || roleUser === "SuperUser") &&
          (editTitle ? (
            <>
              <IconButton aria-label="Cancelar">
                <CancelIcon onClick={handleCancelEdit} sx={{ color: "#FF8C00" }} />
              </IconButton>
              <IconButton onClick={handleSaveImagenFondo} aria-label="Guardar">
                <SaveIcon sx={{ color: "#FF8C00" }} />
              </IconButton>
            </>
          ) : (
            <IconButton
              onClick={handleEditOpen}
              aria-label="Editar"
              sx={{
                backgroundColor: "#FF8C00",
                "&:hover": {
                  backgroundColor: "#dfa967",
                  boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                },
              }}
            >
              <EditIcon />
            </IconButton>
          ))}
        <>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} md={8} sx={{ mb: 6 }} justifyContent={"center"}>
              <MKTypography
                ariant="h1"
                justifyContent={"center"}
                sx={{
                  fontSize: `${isDesktop ? tituloFontSize : tituloFontSize - 15}px`,
                  color: tituloColor,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                <>{Titulo}</>
              </MKTypography>
            </Grid>
          </Grid>
        </>
        <>
          <Grid container spacing={3}>
            {CargdsEquipo.map((item) => (
              <Grid key={item.id} item xs={12} lg={6}>
                <MKBox mb={{ xs: 1, lg: 0 }}>
                  <>
                    <HorizontalCard
                      image={`${apiUrl}${item.imagen}`}
                      name={item.nombre}
                      position={{
                        color: tituloColorCard,
                        colortexto: TextoColorCard,
                        tamano: isDesktop ? tituloFontCardSize : tituloFontCardSize - 8,
                        tamanotexto: isDesktop ? textoFontCardSize : textoFontCardSize - 8,
                      }}
                      title={item.titulo}
                      description={item.descripcion}
                    />
                    {user_id > 0 && (roleUser === "Administrador" || roleUser === "SuperUser") && (
                      <IconButton
                        onClick={() => handleEditItem(item)}
                        aria-label="Editar"
                        sx={{
                          backgroundColor: "#FF8C00",
                          "&:hover": {
                            backgroundColor: "#dfa967",
                            boxShadow: "0px 3px 5px 2px rgb(96 99 54)",
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </>
                </MKBox>
              </Grid>
            ))}
          </Grid>
        </>
        {editItema && (
          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
            <DialogTitle>Editar Elemento</DialogTitle>
            <DialogContent>
              <MKBox mb={2}>
                <MKBox
                  component="img"
                  src={
                    editItema
                      ? cardImagen instanceof File
                        ? URL.createObjectURL(cardImagen)
                        : `${apiUrl}${editItemaData.imagen}`
                      : `${apiUrl}${editItemaData.imagen}`
                  }
                  onDoubleClick={() => {
                    if (editItema) {
                      handleDoubleClick();
                    }
                  }}
                  alt={editItemaData.titulo}
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "12px",
                  }}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  label="Título"
                  value={editItemaData.titulo}
                  onChange={(e) => setEditItemaData({ ...editItemaData, titulo: e.target.value })}
                  sx={{
                    width: "100%",
                    resize: "none",
                  }}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  label="Descripción"
                  value={editItemaData.descripcion}
                  onChange={(e) =>
                    setEditItemaData({ ...editItemaData, descripcion: e.target.value })
                  }
                  multiline
                  minRows={3}
                  maxRows={10}
                  sx={{
                    width: "100%",
                    resize: "none",
                  }}
                />
              </MKBox>
            </DialogContent>
            <DialogActions>
              <MKButton onClick={handleCloseDialog} color="secondary">
                Cancelar
              </MKButton>
              <MKButton onClick={() => handleSave(editItemaData)} color="info">
                Guardar
              </MKButton>
            </DialogActions>
          </Dialog>
        )}
      </Container>
      <Drawer
        title="Editar"
        size="medium"
        open={editOpen}
        onClose={() => handleEditClose(false)}
        footer={
          <Space>
            <MKButton onClick={() => handleEditClose(false)}>Cancelar</MKButton>
            <MKButton type="primary" color="primary" onClick={handleSaveTituloComo}>
              Guardar
            </MKButton>
          </Space>
        }
        sx={{
          "& .ant-drawer-content-wrapper": {
            right: "auto",
            left: "unset",
            width: "60vw",
          },
          "& .ant-drawer-body": {
            padding: 0,
          },
        }}
      >
        <div>
          <MKBox mb={1} sx={{ opacity: 2.6 }}>
            <Grid container justifyContent={"center"}>
              <>
                <MKBox mb={1}>
                  <Grid item>
                    <SoftTypography variant="h6" fontWeight="regular" color="text">
                      <br></br>Fondo&nbsp;&nbsp;&nbsp;
                    </SoftTypography>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                      Color&nbsp;&nbsp;&nbsp;
                    </SoftTypography>
                    <MKInput
                      type="color"
                      value={fondoColor}
                      onChange={(e) => setFondoColor(e.target.value)}
                      label="Color de Fondo"
                      sx={{
                        width: "60px",
                        marginRight: theme.spacing(2),
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <SoftTypography variant="h6" fontWeight="regular" color="text">
                    &nbsp;<br></br>Título&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="color"
                    value={tituloColor}
                    onChange={(e) => setTituloColor(e.target.value)}
                    label="Color"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Tamaño&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="number"
                    value={tituloFontSize}
                    onChange={(e) => setTituloFontSize(e.target.value)}
                    label="Tamaño"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Texto&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  &nbsp;<br></br>
                  <MKInput
                    type="text"
                    // label="Título"
                    value={Titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    sx={{
                      width: "100%",
                      resize: "none",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  <SoftTypography variant="h5" fontWeight="regular" color="text">
                    <br></br>Cards&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color Título&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="color"
                    value={tituloColorCard}
                    onChange={(e) => setTituloColorCard(e.target.value)}
                    label="Color"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Tamaño Título&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="number"
                    value={tituloFontCardSize}
                    onChange={(e) => setTituloFontCardSize(e.target.value)}
                    label="Tamaño"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Color Texto&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="color"
                    value={TextoColorCard}
                    onChange={(e) => setTextoColorCard(e.target.value)}
                    label="Color"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                  &nbsp;<br></br>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    Tamaño Texto&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <MKInput
                    type="number"
                    value={textoFontCardSize}
                    onChange={(e) => setTextoFontCardSize(e.target.value)}
                    label="Tamaño"
                    sx={{
                      width: "60px",
                      marginRight: theme.spacing(2),
                      backgroundColor: "#ffffff",
                    }}
                  />
                  &nbsp;<br></br>
                </MKBox>
              </>
            </Grid>
          </MKBox>
        </div>
      </Drawer>
    </MKBox>
  );
}

Filosofia.propTypes = {
  roleUser: PropTypes.string.isRequired,
  user_id: PropTypes.number,
};

export default Filosofia;
